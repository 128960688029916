import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// google chart loader script is in index.html but required following load call with config
// maps api key uses environment variables so moved to main - for production the key is limited to the domain
google.charts.load('current',
  { packages: ['corechart', 'geochart'],
    mapsApiKey: environment.google.googlecharts
  } );
