import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Vacation } from '../../model/vacation.model';
import { VacationConstants } from '../vacation-constants';
import { StorageService } from '../../services/storage.service';
import { ViewMode } from '../../model/useraccount.model';

@Component({
  selector: 'app-vc-card-grid',
  templateUrl: './vc-card-grid.component.html',
  styleUrls: ['./vc-card-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VcVacationCardGridComponent implements OnInit {
  @Input() vacation: Vacation;
  @Input() viewMode: ViewMode;

  // define enum to use in template
  readonly viewModeList = ViewMode.LIST;
  readonly viewModeGrid = ViewMode.GRID;

  profilePhotoUrl: string;
  readonly thumbDefault = VacationConstants.profilePhotoDefault;

  constructor(private storageService: StorageService) {
  }

  ngOnInit() {
    this.initProfilePhotoUrl();
  }

  getBackgroundImage(vImg: string) {
    return 'url(' + vImg + ')';
  }

  initProfilePhotoUrl() {
    if (this.vacation.profilePhoto && this.vacation.profilePhoto.length > 0) {
      this.profilePhotoUrl = this.vacation.profilePhoto;
    }
  }

}
