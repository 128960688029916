import { Injectable, OnDestroy } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import 'firebase/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAccount, UserAccountFS, Membership, UserSignupType, PageMode } from '../model/useraccount.model';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { StatsService } from './stats.service';
import { Freemium } from '../auth/freemium';
import { Premium } from '../auth/premium';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService implements OnDestroy {

  private userDocRef: AngularFirestoreDocument<UserAccountFS>;
  private userAccountSubscription: Subscription = null;
  private user: UserAccount = null;
  private userDataStats: { numVacations: number } = { numVacations: 0 };
  private loginInProgress = false;

  public userAccountChangeEvent: BehaviorSubject<UserAccount> = new BehaviorSubject<UserAccount>(undefined);

  constructor(private firestoreDB: AngularFirestore,
              private auth: AuthService,
              private stats: StatsService,
              private router: Router) {

    this.auth.userLoginChangeEvent.subscribe(user => {
      if (!user) {
        if (this.userAccountSubscription) {
          // if the user has changed then unsubscribe and get the user account
          this.userAccountSubscription.unsubscribe();
        }
        this.user = null;
        return;
      }

      // check if a new user and if so create the initial UserAccount object
      if (this.auth.newUser) {
        if (this.createNewUserAccount(user)) {
          // reset the new user flag so the user can logout and log back in as an existing user
          this.auth.resetNewUser();

          this.userDocRef = this.firestoreDB.collection('users').doc(user.uid);
          this.loadCurrentUserAccount();

          // Redirect brand new users to the home page to get instructions
          this.auth.redirectUrl = null;
          this.router.navigate(['']);
        } else {
          // TODO - what to do if the user signs in via Google but the UserAccount doesn't get created
          this.auth.logout();
        }
      } else {
        this.userDocRef = this.firestoreDB.collection('users').doc(user.uid);

        // Logging: Log the user login event to keep track of last signin
        // Do this before loading the useraccount object to prevent update event
        this.updateLastSignInAndLoadUserAccount();
      }
    });
  }

  get userAccount(): UserAccount {
    return this.user;
  }

  get accountNumVacations(): number {
    return this.stats.LatestStats.count;
  }

  private loadCurrentUserAccount(): void {
    if (this.userAccountSubscription) {
      // if the user has changed then unsubscribe and get the user account
      this.userAccountSubscription.unsubscribe();
    }

    this.userAccountSubscription = this.userDocRef.valueChanges().pipe(map(userfs => {
      // map the UserAccountFS object to UserAccount
      this.user = new UserAccount(userfs);

      // convert timestamps at the service level to a data object for display
      let signupTimestamp = userfs.signup as firebase.firestore.Timestamp;
      if (!signupTimestamp) {
        // on new user create timestamp is null at first so avoid error
        signupTimestamp = firebase.firestore.Timestamp.now();
      }
      const signupDate = signupTimestamp.toDate();
      this.user.signupDate = signupDate;

      // User the behavior subject to notify and pass the current UserAccount
      this.userAccountChangeEvent.next(this.user);

      // If the user has a setting for the page to display after login
      if (this.auth.loginInProgress) {
        this.auth.loginInProgress = false;
        switch (this.user.prefAfterLoginPage) {
          case PageMode.DASHBOARD:
            this.router.navigate(['dashboard']);
            break;
          case PageMode.VACATIONS:
            this.router.navigate(['vacations']);
            break;
          case PageMode.UPCOMING:
            this.router.navigate(['vacations/show/upcoming']);
            break;
          case PageMode.TIMEOFF:
            this.router.navigate(['daysoff']);
            break;
        }
      }
    })).subscribe();
  }

  async createNewUserAccount(fbUser: firebase.User): Promise<boolean> {
    // Create a User document on new user signup
    const newUser: UserAccountFS = new UserAccountFS();
    newUser.displayName = fbUser.displayName;
    newUser.contactEmail = fbUser.email;
    newUser.signup = firebase.firestore.FieldValue.serverTimestamp();
    newUser.source = UserSignupType.WEB;
    newUser.countryCode = '';
    newUser.membership = Membership.Free;
    newUser.newsletter = true;
    newUser.prefs = {};
    newUser.created = newUser.signup;

    const userDoc = this.firestoreDB.collection('users').doc(fbUser.uid);

    const userData = {};
    Object.assign(userData, newUser);
    try {
      await userDoc.set(userData);
      return true;
    } catch (error) {
      console.log('Cannot add new user: ' + fbUser.uid + ' - ' + fbUser.email);
      return false;
    }
  }

  private async updateLastSignInAndLoadUserAccount(): Promise<void> {
    await this.userDocRef.update({ lastSignin: firebase.firestore.FieldValue.serverTimestamp() });
    this.loadCurrentUserAccount();
  }

  async savePreferences(prefVacationView: string,  prefVacationSort: string, prefAfterLoginPage: string): Promise<void> {
    await this.userDocRef.update({
      prefs:
      {
        VacationView: prefVacationView,
        VacationSort: prefVacationSort,
        AfterLoginPage: prefAfterLoginPage
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userAccountSubscription) {
      this.userAccountSubscription.unsubscribe();
    }
  }

    // Subscription service level limits
    get maxVacation(): number {
      if (this.userAccount.membership == Membership.Premium) {
        return Premium.MAX_VACATIONS;
      }
      return Freemium.MAX_VACATIONS;
    }

    get maxLinks(): number {
      if (this.userAccount.membership == Membership.Premium) {
        return Premium.MAX_LINKS;
      }
      return Freemium.MAX_LINKS;
    }

    get maxHastags(): number {
      if (this.userAccount.membership == Membership.Premium) {
        return Premium.MAX_HASHTAGS;
      }
      return Freemium.MAX_HASHTAGS;
    }

    get maxDestinations(): number {
      if (this.userAccount.membership == Membership.Premium) {
        return Premium.MAX_DESTINATIONS;
      }
      return Freemium.MAX_DESTINATIONS;
    }


}
