import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vc-export',
  templateUrl: './vc-export.component.html',
  styleUrls: ['./vc-export.component.css']
})
export class VcExportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
