import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { VcNotFoundComponent } from './vc-not-found.component';


const notFoundRoutes: Routes = [
  { path: '**', component: VcNotFoundComponent}
];

@NgModule({
  declarations: [
    VcNotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(notFoundRoutes)
  ],
  exports: [RouterModule]
})
export class VcNotFoundModule {

}
