/* Types of Links
Enums are used for mapping string constants saved to database with display names
These types can be used for categorization as well as displaying icons
*/
export enum LinkType {
  LINK = 'Website',
  PHOTO = 'Photo Album',
  VIDEO = 'Video',
  DOC = 'Docs/Files',
  PLAN = 'Travel Plans',
  MAP = 'Map Link'
}

export const LinkTypeIcons: Map<string, string> = new Map<string,string>(
  [
    ['LINK', 'link'],
    ['PHOTO', 'photo_album'],
    ['VIDEO', 'play_circle_filled'],
    ['DOC', 'insert_drive_file'],
    ['PLAN', 'schedule'],
    ['MAP', 'map']
  ]
);

export class LinkFS {
  // model properties
  sort: number;
  name: string;
  linktype: string;
  url: string;

  // meta-_data
  created?: any; // stores firestore server timestamp
  changed?: any; // stores firestore server timestamp
  // deleted?: any; // stores firestore server timestamp

  // default value constructor
  constructor() {
    this.name = '';
    this.sort = 0;
    this.linktype = 'LINK';
    this.url = '';
  }
}

export class Link {
  private _id: string; // Firebase key
  private _data: LinkFS; // interface model _data for Firestore
  private _linkMatIcon = 'link';

  // Data to track add/edit/delete status during Vacation edit mode
  private _added = false;
  private _edited = false;
  private _deleted = false;

  constructor(linkData?: LinkFS) {
    // Initialize the firestore data object to default values
    this._data = new LinkFS();

    if (linkData) {
      this.SetData(linkData);
    }
  }

  get data(): LinkFS {
    return this._data;
  }

  private SetData(data: LinkFS) {
    // When set data from Firestore, apply business logic to not allow bad values and use defaults for missing
    // Each set function should check for null or undefined and min requirements for setting data member
    this.name = data.name;
    this.linktype = data.linktype;
    this.url = data.url;
    this.sort = data.sort;

    // Lookup and store the icon to display in view mode for the list of links
    this._linkMatIcon = LinkTypeIcons.get(this.linktype);
  }

  get id(): string {
    return this._id;
  }
  set id(id: string) {
    this._id = id;
  }

  get sort(): number {
    return this._data.sort;
  }
  set sort(sort: number) {
    this._data.sort = sort;
  }

  get name(): string {
    return this._data.name;
  }
  set name(name: string) {
    if (name && name.length > 0) {
      this._data.name = name;
    }
  }

  get linktype(): string {
    return this._data.linktype;
  }
  set linktype(linktype: string) {
    if (linktype) {
      this._data.linktype = linktype;
    }
  }

  get linkClass(): string {
    return this._linkMatIcon;
  }

  get url(): string {
    return this._data.url;
  }
  set url(url: string) {
    if (url) {
      this._data.url = url;
    }
  }

  set added(added: boolean) {
    this._added = added;
  }
  get added(): boolean {
    return this._added;
  }
  set edited(edited: boolean) {
    this._edited = edited;
  }
  get edited(): boolean {
    return this._edited;
  }
  set deleted(deleted: boolean) {
    this._deleted = deleted;
  }
  get deleted(): boolean {
    return this._deleted;
  }

  public static getLinkTypeEnum(key: string) : LinkType | undefined {
    return Object.values(LinkType).find(x => x === key);
  }

}
