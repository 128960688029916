import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CountryCodes } from '../../shared/world-data';
import { DestinationType, Destination } from '../../model/destination.model';

@Component({
  selector: 'app-vc-destination-view',
  styleUrls: ['./vc-vacation-view.component.scss'],
  template: `
  <mat-list-item class="vc-destinationlist-item">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="24px">
      <div fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row">
          <mat-icon class="vc-destination-icon"[svgIcon]="destination?.getDestIcon()"></mat-icon>
          <h3 class="vc-destination-title">{{ destination.name }}</h3>
        </div>
        <div>{{ destination?.description }}</div>
      </div>
      <div fxLayout="column" fxFlexAlign="end end">
        <a class="vc-destination-maplink" [href]="mapLink()" target="_blank" rel="noopener">
          <mat-icon class="vc-destination-maplink">directions</mat-icon></a>
        <span class="vc-destination-country">{{ destination?.countryName }}</span>
      </div>
    </div>
  </mat-list-item>
  `
})
export class VcDestinationViewComponent implements OnInit {

  @Input() destination: Destination;

  private readonly googleMaps = 'https://www.google.com/maps/search/?api=1&query=';

  constructor() {
  }

  ngOnInit() {
  }

  mapLink(): string {
    // Create a Google Maps query string to link to the destination (place) name plus country
    // TODO - confirm if the correct connector is + or , and also if encode url should use + as spaces - see examples
    let url = this.googleMaps + this.getDestinationNameEncoded() + '+' + this.getCountryNameEncoded();

    return url;
  }

  /* mapEmbedSrc() {
    if (this.destination) {
      const embed = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCV-QfKtcC8to7x5afeb0-qLRNWsKHOAwI&q='
      + this.getDestinationNameEncoded() + '+' + this.getCountryNameEncoded();
      return this.sanitizer.bypassSecurityTrustResourceUrl(embed);
    }
    return '';
  } */

  getDestinationNameEncoded(): string {
    return encodeURI(this.destination.name);
  }

  getCountryNameEncoded(): string {
    if (this.destination && this.destination.countryCode) {
      return encodeURI(this.destination.countryName);
    }
    return '';
  }

  getDestinationType(): string {
    if (this.destination && this.destination.type) {
      return this.getEnumKeyByEnumValue(DestinationType, this.destination.type);
    }
    return '';
  }

  getEnumKeyByEnumValue<T extends {[index:string]:string}>(myEnum:T, enumValue:string):keyof T|null {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
}
