import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { AuthService } from '../auth/auth.service';
import { Link, LinkFS } from '../model/link.model';
import { VacationFS } from '../model/vacation.model';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  private linksColl: AngularFirestoreCollection<LinkFS>;
  private linksList$: Observable<Link[]>;

  constructor(private readonly firestoreDB: AngularFirestore, private readonly auth: AuthService) { }

  getVacationLinks(vid: string): Observable<Link[]> {
    console.log('get links collection observable');

    // return the set of links for the current vacation including ids for edit/update/delete operations
    this.linksColl = this.firestoreDB.
      collection('users').doc(this.auth.getcurrentUserId()).collection('vacations').doc(vid)
      .collection('links', ref => ref.orderBy('sort'));
    this.linksList$ = this.linksColl.snapshotChanges().pipe(
      map(changes => { return changes.map(a => {
        const data = a.payload.doc.data() as LinkFS;
        const link = new Link(data);
        link.id = a.payload.doc['id'];
        return link;
      });
    }));

    return this.linksList$;
  }

  addNewLinks(batch: firebase.firestore.WriteBatch, vacationDoc: AngularFirestoreDocument<VacationFS>, links: Link[]) {
    links.forEach(link => {
      // set the created and changed on date timestamp values using server time functions
      link.data.created = firebase.firestore.FieldValue.serverTimestamp();
      link.data.changed = link.data.created;

      // Add each link object to the links subcollection under the vacation id
      // Convert the custom object to a plan Object to be safe and more compatible with Firestore SDK
      // must create the auto id here since the doc() call requires an id, whereas the SDK is optional for new/generate
      const linkData = {};
      Object.assign(linkData, link.data);
      const did = this.firestoreDB.createId();

      batch.set(vacationDoc.collection('links').doc(did).ref, linkData);
    });
  }

  updateLinks(batch: firebase.firestore.WriteBatch, vacationDoc: AngularFirestoreDocument<VacationFS>, links: Link[]) {
    // if any deleted then sort order will change so save all destinations that follow in order not just edited
    let anyDeleted = false;

    links.forEach(link => {
      // if the link was added as new and not deleted during the same edit session, add it as new
      if (link.added && !link.deleted) {
        link.data.created = firebase.firestore.FieldValue.serverTimestamp();
        link.data.changed = link.data.created;

        const linkData = {};
        Object.assign(linkData, link.data);
        const did = this.firestoreDB.createId();
        batch.set(vacationDoc.collection('links').doc(did).ref, linkData);

      } else if (link.deleted) {
        anyDeleted = true;
        batch.delete(vacationDoc.collection('links').doc(link.id).ref);

      } else if (link.edited || anyDeleted) {
        if (link.edited) {
          // set the created and changed on date timestamp values using server time functions
          link.data.changed = firebase.firestore.FieldValue.serverTimestamp();
        }
        const linkData = {};
        Object.assign(linkData, link.data);
        batch.update(vacationDoc.collection('links').doc(link.id).ref, linkData);
      }
    });
  }

  deleteLinksForVacation(batch: firebase.firestore.WriteBatch,
                         uid: string, vid: string, linkIds: string[]) {
    // There is no delete collection/query so must delete each old Destination document one by one in the batch operation
    linkIds.forEach(linkId => {
      const linkDoc = this.firestoreDB.collection('users').doc(uid).collection('vacations')
        .doc(vid).collection('links').doc(linkId);
      batch.delete(linkDoc.ref);
    });
  }
}
