import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  static readonly profilePhotoName = 'profile.jpg';
  static readonly photoExt = '.jpg';
  static readonly photoThumbSize: number = 360;

  constructor(private readonly auth: AuthService,
              private storage: AngularFireStorage) {
  }

  getVacationProfilePhotoLocation(vid: string): string {
    // The fixed path is a Storage bucket folder bucket/{userId}/[photo|thumb]/{vacationId}.JPG
    return '/users/' + this.auth.getcurrentUserId() + '/vacations/' + vid + '/'
      + StorageService.profilePhotoName;
  }

  getVacationProfilePhotoDownloadUrl(vid: string): Observable<string> {
    const photoRef = this.storage.ref(this.getVacationProfilePhotoLocation(vid));
    return photoRef.getDownloadURL();
  }

  // The vacation photos are uploaded via the FileUploadComponent
  // However delete must be done as a service
  deleteVacationProfilePhoto(vid: string) {
    const photoRef = this.storage.ref(this.getVacationProfilePhotoLocation(vid));
    photoRef.delete().toPromise()
    .then( () => {
      return true;
    })
    .catch( (reason: any) => {
      console.log(reason);
      return false;
    });
  }

}
