import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { UserAccountService } from 'src/app/services/useraccount.service';

@Component({
  selector: 'app-vc-hashtag-editor',
  templateUrl: './vc-hashtag-editor.component.html',
  styleUrls: ['./vc-hashtag-editor.component.scss']
})
export class VcHashtagEditorComponent {

  // @ViewChild('hashtagInput', { static : false }) hashtagInput: ElementRef;
  private allHashtagsList: string[];
  private hashtagsList: string[] = [];

  // Represents the input box that is bound to the autocomplete list observable
  hashtagCtrl = new FormControl();
  // Represents the input box as an autocomplete panel which can be closed
  @ViewChild('hashtagInput', { read: MatAutocompleteTrigger }) hashtagInput: MatAutocompleteTrigger;
  @ViewChild('hashtagInput2') hashtagInput2: MatInput;

  filteredHashtags: Observable<string[]>;

  selectable = false;
  removable = true;
  addOnBlur = false;
  // Doesn't matter if tab is included as custom hashtag input is in own form so tabbing is same as submit/enter
  separatorKeysCodes = [ENTER, COMMA, SPACE, TAB];

  addHashtagName: string;

  constructor(private readonly userService: UserAccountService) {
  }

  setHashtagList(allHashtags: string[], checked: string[]) {
    this.allHashtagsList = allHashtags;
    this.hashtagsList = checked;

    // Remove the selected from the all list
    if (this.allHashtagsList) {
      this.hashtagsList.forEach(hashtag => {
        if (this.allHashtagsList.includes(hashtag)) {
          this.allHashtagsList.splice(this.allHashtagsList.indexOf(hashtag), 1);
        }
      });
    }

    // When the all hashtags list is set by the parent component, then it is ready to be bound to the hashtag autocomplete list
    if (this.allHashtagsList) {
      this.filteredHashtags = this.hashtagCtrl.valueChanges.pipe(
        startWith(null as string),
        map((typedChars: string | null) =>
          typedChars ? this.filterAutocompleteList(typedChars) : this.allHashtagsList.slice()));
    }
  }
  get selectedHashtags(): string[] {
    return this.hashtagsList;
  }

  filterAutocompleteList(name: string) {
    return this.allHashtagsList.filter(hashtag =>
      hashtag.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  addHashtag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Check to make sure the hashtag isn't already assigned to this vacation
    if (this.hashtagsList.length < this.userService.maxHastags
        && !this.hashtagsList.includes(value.trim())) {
      // Add the hashtag to the list
      if ((value || '').trim()) {
        this.hashtagsList.push(value.trim().toLowerCase());

        // remove the hashtag from the all list to avoid double select
        this.removeFromAutoComplete(value);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
      this.hashtagInput.closePanel();
    }

    this.hashtagCtrl.setValue(null);
  }

  removeHashtag(hashtag: string): void {
    const index = this.hashtagsList.indexOf(hashtag);

    if (index >= 0) {
      this.hashtagsList.splice(index, 1);

      // add it back to the autocomplete so the user can add it back again if desired
      this.allHashtagsList.push(hashtag);
      // Reset the input to refresh the observable filtered auto-complete list
      this.hashtagCtrl.reset();
    }
  }

  removeFromAutoComplete(hashtag: string): void {
    const index = this.allHashtagsList.indexOf(hashtag);

    if (index >= 0) {
      this.allHashtagsList.splice(index, 1);
      // Reset the input to refresh the observable filtered auto-complete list
      this.hashtagCtrl.reset();
    }
  }

  selectHashtagListItem(event: MatAutocompleteSelectedEvent): void {
    if (this.hashtagsList.length < this.userService.maxHastags) {
      this.hashtagsList.push(event.option.value);

      // remove the hashtag from the all list to avoid double select
      this.removeFromAutoComplete(event.option.value);
    }

    this.hashtagInput.writeValue('');
    this.hashtagCtrl.setValue(null);
    this.hashtagInput.closePanel();
  }

  suppressEnterKey(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      console.log('supressing enter');
      event.preventDefault();
    }
  }

  doNothing(event: KeyboardEvent) {
    event.preventDefault();
    // return false;
  }
}
