import { Injectable } from '@angular/core';
import { Vacation, VacationFS } from '../model/vacation.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import * as moment from 'moment';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { AuthService } from '../auth/auth.service';
import { DestinationService } from './destinations.service';
import { Destination } from '../model/destination.model';
import { Link } from '../model/link.model';
import { LinksService } from './links.service';
import { StorageService } from './storage.service';
import { Tag } from '../model/tag.model';
import { ViewMode, SortMode, PageMode } from '../model/useraccount.model';
import { UserAccountService } from './useraccount.service';

@Injectable({
  providedIn: 'root'
})
export class VacationService {

  private vacationColl: AngularFirestoreCollection<VacationFS>;
  private vacationList$: Observable<Vacation[]>;
  private vacationObject$: Observable<Vacation>;

  // session preferences loaded from user account preferences loaded at login time
  currentViewMode = ViewMode.GRID;
  currentSortMode = SortMode.DATE_DESC;
  currentDefaultPage = PageMode.DASHBOARD;

  constructor(private readonly firestoreDB: AngularFirestore,
              private readonly auth: AuthService,
              private readonly userService: UserAccountService,
              private readonly destinationService: DestinationService,
              private readonly linkService: LinksService,
              private readonly storageService: StorageService) {

      this.userService.userAccountChangeEvent.subscribe( user => {
        // set the relevant user preferences as defaults for the components
        if (user) {
          if (user.prefs && user.prefVacationSort) {
            this.currentSortMode = user.prefVacationSort;
          }
          if (user.prefs && user.prefVacationView) {
            this.currentViewMode = user.prefVacationView;
          }
          if (user.prefs && user.prefAfterLoginPage) {
            this.currentDefaultPage = user.prefAfterLoginPage;
          }
        }
      });
  }

  private getSortByField(sortBy: SortMode): string {
    switch (sortBy) {
      case SortMode.NAME_ASC:
      case SortMode.NAME_DESC:
        return 'name';
      case SortMode.DATE_ASC:
      case SortMode.DATE_DESC:
        return 'dateFrom';
    }
  }

  private getSortByDirection(sortBy: SortMode): firebase.firestore.OrderByDirection {
    switch (sortBy) {
      case SortMode.NAME_ASC:
      case SortMode.DATE_ASC:
        return 'asc';
      case SortMode.NAME_DESC:
      case SortMode.DATE_DESC:
        return 'desc';
    }
  }

  getVacationsListByTag(filterTag: string): Observable<Vacation[]> {

    if (filterTag && filterTag.length > 0) {

      // Special handling for upcoming vacations since not a tag but a date from comparison operator
      if (filterTag === Tag.UPCOMING) {
        return this.getVacationsUpcoming();
      }
      // Built-in tags are sorted by name
      this.vacationColl = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).
        collection<VacationFS>('vacations', ref => ref.where(filterTag, '==', true)
      );
    }
    return this.getVacationsCollection();
  }

  /*
  Hashtags are custom and so cannot be covered by an index which is required to query where clause plus sort field
  Sort these client side and consider if this can be optimized in the future to sort all server OR client
  If pagination is needed in the future then getting the whole dataset is not necessary but hashtags
  should not get too many results for most users so probably fine
  */
  getVacationsListByHashtag(hashtag: string): Observable<Vacation[]> {

    if (hashtag && hashtag.length > 0) {
      const hashtagField = 'hashtags.' + hashtag;

      this.vacationColl = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).
      collection<VacationFS>('vacations', ref => ref.where(hashtagField, '==', true)
      );
    }

    return this.getVacationsCollection();
  }

  async getAllVacations(): Promise<Vacation[]> {
    const coll: AngularFirestoreCollection<VacationFS> =
      this.vacationColl = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).collection<VacationFS>
      ('vacations', ref => ref.orderBy('dateFrom', 'asc'));
    const vfs = await coll.ref.get();
    const vacations = vfs.docs.map(a => {
      const data = a.data() as VacationFS;
      const vacation = new Vacation(data);
      vacation.id = a.id;
      return vacation;
    } );
    return vacations;
  }

  // Get the list of vacations sorted by date from oldest to newest
  getVacationsListByYear(year?: string): Observable<Vacation[]> {

    // get all vacations for a year and sort by date from beginning to end of year
    const sortBy = SortMode.DATE_DESC;

    // in the future there might be a need to page by year and not return all years vacations
    if (year) {
      // sort client side for year list
      this.vacationColl = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).collection<VacationFS>
        ('vacations', ref => ref.where('year', '==', year));
    } else {
      // return all vacations and sort by newest first
      this.vacationColl = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).collection<VacationFS>
      ('vacations', ref => ref.orderBy(this.getSortByField(sortBy), this.getSortByDirection(sortBy)));
    }

    return this.getVacationsCollection();
  }

  public getVacationsUpcoming(): Observable<Vacation[]> {

    const todayStr = moment().format(Vacation.FB_DATE_STR);
    // Dashboard shows upcoming vacations - click to show in list view incl already started
    this.vacationColl = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).
      collection<VacationFS>('vacations', ref => ref.where('dateTo', '>', todayStr));

    return this.getVacationsCollection();
  }

  private getVacationsCollection(): Observable<Vacation[]> {

    console.log('get vacations collection from Firestore');

    // Implementation notes: get the observable objects from Firestore database and let the caller Subscribe
    // This means the caller has to subscribe and unsubscribe on Destroy
    // Previously this was in the constructor which seemed to cause all sorts of problems that hopefully are fixed
    // TODO - test to make sure this isn't causing extra calls to the firestore db server

    // Get the firestore collection of vacations ordered by name - TODO: Use order by control
    // this.vacationColl = this.firestoreDB.collection<VacationFS>('vacations', ref => ref.orderBy('name', 'asc'));

    // Subscribe to the value changes from the observable object to always have the latest vacation list data
    // Since the keys are not stored in the data object, SnapshotChanges is required to get the id from the metadata
    // this is the code to be used to add it to the IVacation object after async data is retrieved from Firestore
    this.vacationList$ = this.vacationColl.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as VacationFS;
        const vacation = new Vacation(data);
        vacation.id = a.payload.doc['id'];
        return vacation;
      });
    }));

    return this.vacationList$;
  }

  getVacationById(vid: string): Observable<Vacation> {

    console.log('get vacation by id from Firestore');

    this.vacationObject$ = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true))
      .collection('vacations').doc(vid).valueChanges().pipe(map(obj => {
      // get the VacationFS object and embed the data in a Vacation model object as an observable
      const data = obj as VacationFS;
      const vacation = new Vacation(data);
      vacation.id = vid;
      return vacation;
    }));
    return this.vacationObject$;
  }

  generateNewVacationId(): string {
    // Create a unique ID for the vacation to be added to the Firestore database
    // We can't pass the ID as a data member since we don't want to store it as a member
    // Need to call set on the new document which will get created if not exist
    return this.firestoreDB.createId();
  }

  // Add New Vacation and its Destinations to Firestore
  addNewVacation(vid: string, vacation: VacationFS,
                 destArray: Destination[], links: Link[]): Observable<any> {

    console.log('addVacationAndDestinations to Firestore');

    // add the vacation and destinations in a batch to succeed or fail as one operation
    // use a subject to return thew new vacation id to the subscriber
    const subject: Subject<any> = new Subject<any>();

    // set the created and changed on date timestamp values using server time functions
    vacation.created = firebase.firestore.FieldValue.serverTimestamp();
    vacation.changed = vacation.created;

    // Set the vacation ID onto each of the destination objects
    destArray.forEach(dest => {
      dest.vacationId = vid;
    });

    // Use a batch transaction add the Vacation and the Destination Documents to Firestore
    const batch: firebase.firestore.WriteBatch = this.firestoreDB.firestore.batch();
    const vacationDocument: AngularFirestoreDocument<VacationFS> =
      this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).collection('vacations').doc(vid);

    // Convert the custom object to a plan Object to be safe and more compatible with Firestore SDK
    const vacationData = {};
    Object.assign(vacationData, vacation);
    batch.set(vacationDocument.ref, vacationData);

    // add the destination documents to the user referecing this new vacation
    this.destinationService.addDestinations(batch, this.auth.getcurrentUserId(), destArray);

    // Add the list of links in the sub collection under vacation
    this.linkService.addNewLinks(batch, vacationDocument, links);

    batch.commit().then(value => {
      // All is good
      subject.next(vid);
      subject.complete();
    })
    .catch(reason => {
      console.log(reason);
      subject.error(reason);
      subject.complete();
    });

    return subject;
  }

  // Update New Vacation and set the list of associated Destinations to Firestore
  updateExistingVacation(vid: string,
                         vacation: VacationFS,
                         oldDestinationIds: string[],
                         destArray: Destination[],
                         links: Link[]): Observable<any> {

    console.log('updateVacationAndDestinations in Firestore');

    // add the vacation and destinations in a batch to succeed or fail as one operation
    const subject = new Subject<any>();

    // set the changed on date timestamp values using server time functions
    vacation.changed = firebase.firestore.FieldValue.serverTimestamp();

    // Use batch to add the Vacation, delete old Destinations, add new list of Destination Documents to Firestore
    const batch: firebase.firestore.WriteBatch = this.firestoreDB.firestore.batch();
    const vacationDocument: AngularFirestoreDocument<VacationFS> =
      this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).collection('vacations').doc(vid);

    // Convert the custom object to a plan Object to be safe and more compatible with Firestore SDK
    const vacationData = {};
    Object.assign(vacationData, vacation);
    batch.update(vacationDocument.ref, vacationData);

    // Update the collection of destinations - list tracks which are added/edited/deleted
    this.destinationService.updateDestinations(batch, this.auth.getcurrentUserId(), destArray);

    // Update the list of links in the sub collection under vacation
    this.linkService.updateLinks(batch, vacationDocument, links);

    batch.commit().then(value => {
      // All is good - nothing to return
      subject.complete();
    })
    .catch(reason => {
      console.log(reason);
      subject.error(reason);
      subject.complete();
    });

    return subject;
  }

  deleteVacation(vid: string, destinationIds: string[], linkIds: string[], hasPhoto: boolean): Observable<any> {
    // set the deleted on date timestamp values using server time functions
    // vacation.deleted = firestore.FieldValue.serverTimestamp();

    // Delete the Vacation document, related Destination documents, and storage for this vacation
    const subject = new Subject<any>();
    const batch: firebase.firestore.WriteBatch = this.firestoreDB.firestore.batch();

    // Delete profile photo in Firebase Storage if it exists
    if (hasPhoto) {
      this.storageService.deleteVacationProfilePhoto(vid);
    }

    const vacationDocument: AngularFirestoreDocument<VacationFS> =
      this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId(true)).collection('vacations').doc(vid);

      // Delete the subcollections for this vacation
    this.destinationService.deleteDestinationsForVacation(batch, this.auth.getcurrentUserId(), destinationIds);
    this.linkService.deleteLinksForVacation(batch, this.auth.getcurrentUserId(), vid, linkIds);

    batch.delete(vacationDocument.ref);

    batch.commit().then(value => {
      // All is good - nothing to return
      subject.complete();
    })
    .catch(reason => {
      console.log(reason);
      subject.error(reason);
      subject.complete();
    });

    return subject;
  }
}
