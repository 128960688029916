import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserAccountComponent } from './user/user-account/user-account.component';
import { AuthGuard } from './auth/auth-guard.service';
import { HomeComponent } from './core/home/home.component';
import { ErrorPageComponent } from './core/error-page/error-page.component';
import { SigninPageComponent } from './core/signin/signin-page.component';
import { VcBucketListComponent } from './travel/vc-bucket-list/vc-bucket-list.component';
import { VcUpgradeComponent } from './core/vc-upgrade/vc-upgrade.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'signin', component: SigninPageComponent },
  { path: 'useraccount', component: UserAccountComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', loadChildren: () => import('./counts/counts.module').then(m => m.CountsModule) },
  { path: 'vacations', loadChildren: () => import('./vacation/vacation.module').then(m => m.VacationModule) },
  { path: 'daysoff', loadChildren: () => import('./days/days.module').then(m => m.DaysModule) },
  { path: 'travel', loadChildren: () => import('./travel/travel.module').then(m => m.TravelModule) },
  { path: 'bucketlist', component: VcBucketListComponent, canActivate: [AuthGuard] },
  { path: 'upgrade', component: VcUpgradeComponent, canActivate: [AuthGuard]},
  { path: 'upgrade', component: VcUpgradeComponent, canActivate: [AuthGuard]},
  { path: 'error', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
