import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { VcSidebarComponent } from './vc-sidebar/vc-sidebar.component';
import { RouterModule } from '@angular/router';
import { VCMaterialModule } from '../design/vcmaterial.module';
import { NewUserComponent } from './new-user/new-user.component';
import { SigninComponent } from './signin/widget/signin.component';
import { SigninPageComponent } from './signin/signin-page.component';
import { VcUpgradeComponent } from './vc-upgrade/vc-upgrade.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { VcExportComponent } from './vc-export/vc-export.component';

@NgModule({
  declarations: [
    HomeComponent,
    SigninComponent,
    SigninPageComponent,
    HeaderComponent,
    FooterComponent,
    VcSidebarComponent,
    NewUserComponent,
    VcUpgradeComponent,
    ErrorPageComponent,
    VcExportComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    VCMaterialModule,
    SharedModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    VcSidebarComponent,
    HomeComponent,
    VcUpgradeComponent,
    VcExportComponent
  ],
  providers: [
  ]
})
export class CoreModule {}
