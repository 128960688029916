import { ValidatorFn, AbstractControl } from '@angular/forms';

export const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

export function validateUrl(ctrl: AbstractControl): { [key: string]: boolean } | null {
    const urlValue = ctrl.value;
    // const valid = /^(http|https):\/\/[^ "]+$/.test(urlValue);

    const pattern = new RegExp(urlRegex);

    return !pattern.test(urlValue) ? { valid: false } : null;
}
