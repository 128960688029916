import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  version: string = environment.VERSION;

  href_terms: string = environment.vc.termsUrl;
  href_privacy: string = environment.vc.privacyUrl;
  href_credits: string = environment.vc.creditsUrl;

  href_help: string = environment.vc.helpUrl;
  href_blog: string = environment.vc.blogUrl;
  href_support: string = environment.vc.supportUrl;
  href_meliovation: string = environment.vc.meliovationUrl;

  constructor() { }

  ngOnInit() {
  }

}
