
export const RECENT_MAX = 10;
export const VACATION_STATS_DOC = 'summary';

export class StatListItem {
  public id = '';
  public itemName = '';
  public stat = 0;  // specific to the list e.g. counts
  public icon = ''; // used for icon css class or img name
  public data = ''; // to be used for data specific to each type of list

  constructor() {
  }
}
export class StatYear {
  public count = 0;
  public days = 0;
  public destinations = 0;
  public countries = 0;

  constructor() {
  }
}
export interface StatYearType {
  [year: string]: StatYear;
}

export class StatsSummary {

  // Sidebar link counts
  public count: number;
  public countTaken: number;
  public domestic: number;
  public foreign: number;
  public top: number;
  public upcoming: number;
  public hashtagCounts: { [hashtag: string]: number };
  public vacationLengths: { [lenEnum: string]: number };

  // Dashboard stats
  public totalDays: number;
  public avgDays: number;
  public daysSince: number;

  public perYear: StatYearType = {};
  public recentVacations: StatListItem[] = [];
  public topVacations: StatListItem[] = [];
  public upcomingVacations: StatListItem[] = [];

  public countDestinations: number;
  public countCountries: number;
  public countDestinationTypes: StatListItem[] = [];
  public countryTotals: StatListItem[] = [];
  public recentDestinations: StatListItem[] = [];

  public generatedOn: any = null;

  // Helper
  public thisYear: string;
  public lastYear: string;

  constructor() {
    this.count = 0;
    this.countTaken = 0;
    this.domestic = 0;
    this.foreign = 0;
    this.top = 0;
    this.upcoming = 0;
    this.hashtagCounts = {};

    this.totalDays = 0;
    this.avgDays = 0;
    this.daysSince = 0;

    const today = new Date();
    this.thisYear = today.getFullYear().toString();
    this.lastYear = (today.getFullYear() - 1).toString();
    // Default the per year object with this year and last year for dashboard stats defaults
    this.perYear = {
      [this.thisYear]: new StatYear(),
      [this.lastYear]: new StatYear()
    };

    this.vacationLengths = {
      day: 0,
      weekend: 0,
      oneweek: 0,
      twoweek: 0,
      longer: 0
    };

    this.recentVacations = [];
    this.topVacations = [];
    this.upcomingVacations = [];

    this.countDestinations = 0;
    this.countCountries = 0;
    this.countDestinationTypes = [];
    this.countryTotals = [];
    this.recentDestinations = [];

  }

  get isEmpty() {
    return !this.generatedOn;
  }
}
