export class Tag {
  name: string;
  count: number;

  public static DOMESTIC = "domestic";
  public static INTERNATION = "international";
  public static TOP = "top";
  public static UPCOMING = "upcoming";

  constructor(name: string, count: number) {
    this.name = name;
    this.count = count;
  }

}

export class Hashtag extends Tag {

  // List of hashtags to offer to the user when they have no custom hashtags defined
  // After they have at least a few of their own, leave out these from the all hashtags list
  public static HashtagsBuiltIn: string[] = [
    'adventure',
    'weekend',
    'luxury',
    'family',
    'solo',
    'beach',
    'relaxation',
    'driving',
    'outdoors',
    'romantic'
  ];

  // return the list of all hashtags as a string array - include the built-in as necessary
  public static getHashtagsWithDefaults(hashtagList: string[]): string[] {
    // If the all hashtags list is 5 or less, include the built-in hashtags with the user defined
    if (hashtagList.length < 6) {
      Hashtag.HashtagsBuiltIn.forEach(builtin => {
        if (!hashtagList.includes(builtin)) {
          hashtagList.push(builtin);
        }
      });
    }
    return hashtagList.sort();
  }
}

