import {
  Component,
  OnInit,
  Pipe,
  PipeTransform,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { UserAccount } from "../../model/useraccount.model";
import { ViewMode, SortMode, PageMode } from "../../model/useraccount.model";
import { Title } from "@angular/platform-browser";
import { UserAccountService } from "../../services/useraccount.service";
import { environment } from "../../../environments/environment";
import { Subscription } from "rxjs";
import { ExportService } from "src/app/services/export.service";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Freemium } from '../../auth/freemium';


@Component({
  selector: "app-user-account",
  templateUrl: "./user-account.component.html",
  styleUrls: ["./user-account.component.scss"]
})
export class UserAccountComponent implements OnInit, OnDestroy {
  user: UserAccount;
  subUserAccount: Subscription;

  // Preferences
  prefVacationView: ViewMode;
  prefVacationSort: SortMode;
  prefAfterLoginPage: PageMode;
  settingsSaved = false;
  allowExport = true;
  exportStatus = 0;   // 1 = exporting  2 = export ready
  exportFile = "";
  vacationsCount = 0;
  vacationsMax = 0;

  hrefSupport = environment.vc.supportUrl;

  constructor(
    private usersService: UserAccountService,
    private readonly storage: AngularFireStorage,
    private readonly userService: UserAccountService,
    private exportService: ExportService,
    private titleService: Title
  ) {
    this.settingsSaved = false;
  }

  ngOnInit(): void {
    // get the current user account to display in the UI
    this.subUserAccount = this.usersService.userAccountChangeEvent.subscribe(
      (userAccount) => {
        this.user = userAccount;
        this.prefVacationView = this.user.prefVacationView;
        this.prefVacationSort = this.user.prefVacationSort;
        this.prefAfterLoginPage = this.user.prefAfterLoginPage;
        this.vacationsMax = this.userService.maxVacation;
      }
    );

    this.titleService.setTitle("User Account - My VacationCounts");

    // get the number of vacations stored to display in the account data
    this.vacationsCount = this.usersService.accountNumVacations;
  }

  saveSettings(): void {
    // Save the user preferences to the user account
    this.settingsSaved = false;
    this.usersService.savePreferences(
      this.prefVacationView,
      this.prefVacationSort,
      this.prefAfterLoginPage
    );
    // display the save label
    this.settingsSaved = true;
  }

  ngOnDestroy(): void {
    if (this.subUserAccount) {
      this.subUserAccount.unsubscribe();
    }
  }

  async exportUserData() {
    this.exportStatus = 1;
    const exportUrl = (await this.exportService.exportAllUserData()).downloadUrl;
    console.log(exportUrl);
/*     if (!exportUrl || !exportUrl.endsWith(".html")) {
      this.exportStatus = 0;
      return "";
    }
 */
    // get the download Url from the Storage api with user token
    this.exportStatus = 2;
    this.exportFile = await this.storage.ref(exportUrl).getDownloadURL().toPromise();
  }
}
