import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Hashtag } from '../../model/tag.model';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { StatsService } from '../../services/stats.service';
import { StatsSummary } from '../../model/stats.model';
import { MediaMatcher } from '@angular/cdk/layout';

interface yearTag {
  year: string;
  count: number;
}

@Component({
  selector: 'app-vc-sidebar',
  templateUrl: './vc-sidebar.component.html',
  styleUrls: ['./vc-sidebar.component.scss']
})
export class VcSidebarComponent implements OnInit, OnDestroy {

  @Input() mobileTopMargin: number = 0;

  total = 0;
  domesticCount = 0;
  foreignCount = 0;
  topCount = 0;
  upcomingCount = 0;

  years: yearTag[] = [];
  hashtags: Hashtag[] = [];
  accountSubscription: Subscription;

  stats: StatsSummary = new StatsSummary();
  statsSub: Subscription = null;

  constructor(private readonly statsService: StatsService,
              private readonly authService: AuthService,) {
  }

  ngOnInit() {
    // When the user is logged in whether newly or from before and opens app
    // Load the user account object to get the hashtags and subscribe for updates
    this.statsSub = this.statsService.statsChangeEvent.subscribe( latestStats => {
      this.resetCounts();
      this.stats = latestStats;
      if (!this.stats.isEmpty) {
        this.setStatsInSidebar();
      }
    });
  }

  private setStatsInSidebar() {
    // Add a link to the vacation year from stats with counts
    // This is how the user can navigate to a specific year
    this.years = [];
    for (let statKey of Object.keys(this.stats.perYear)) {
      let year = this.stats.perYear[statKey];
      let yearStat: yearTag = { year: statKey, count: year.count };
      this.years.push(yearStat);
    }

    // set the counts for the sidebar links
    this.total = this.stats.count;
    this.domesticCount = this.stats.domestic;
    this.foreignCount = this.stats.foreign;
    this.topCount = this.stats.top;
    this.upcomingCount = this.stats.upcoming;

     // Create hashtag objects to bind to the display
     this.hashtags = [];
     for (const tag in this.stats.hashtagCounts) {
       this.hashtags.push(new Hashtag(tag, this.stats.hashtagCounts[tag]));
     }
  }

  resetCounts() {
    this.total = 0;
    this.domesticCount = 0;
    this.foreignCount = 0;
    this.topCount = 0;
    this.years = [];
    this.hashtags = [];
  }

  ngOnDestroy() {
    if (this.statsSub) {
      this.statsSub.unsubscribe();
    }
  }

  get disabledNotLoggedIn() {
    return this.authService.isAuthenticated();
  }

  closeMenu() {
    // TODO ?
  }

}
