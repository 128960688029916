import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Vacation } from '../../model/vacation.model';
import { ViewMode } from '../../model/useraccount.model';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { Tag } from '../../model/tag.model';
import { SortMode } from '../../model/useraccount.model';
import { UserAccountService } from '../../services/useraccount.service';
import { VacationService } from '../../services/vacation.service';


@Component({
  selector: 'app-vc-list',
  templateUrl: './vc-list.component.html',
  styleUrls: ['./vc-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VcListComponent implements OnInit, OnDestroy {

  @ViewChild('group1') viewToggle: MatButtonToggleGroup;
  @ViewChild('group2') sortToggle: MatButtonToggleGroup;

  vacationObs$: Observable<Vacation[]>;
  vacationSorted: Vacation[] = null;
  vacationSubs: Subscription = null;
  navSubs: Subscription = null;

  noVacations = true;
  cardGridLayout = 'column';

  filterTag: string = null;
  filterYear: string = null;
  filterHashtag: string = null;

  constructor(private vacationService: VacationService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private userService: UserAccountService,
    private titleService: Title) {

    this.navSubs = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        // set the initial view mode to preference and subsequent component creation, the current view mode stored in the service
        this.onChangeViewMode(this.vacationService.currentViewMode);

        if (this.route.snapshot.paramMap.has('year')) {
          this.filterYear = this.route.snapshot.paramMap.get('year');
        } else {
          this.filterYear = null;
        }

        if (this.route.snapshot.data['tag']) {
          this.filterTag = this.route.snapshot.data['tag'];
        } else {
          this.filterTag = null;
        }

        if (this.route.snapshot.data['hashtag']) {
          // with paramMap use the get function to get one
          // and the snapshot to get the latest and not listen to changes via observable
          this.filterHashtag = this.route.snapshot.paramMap.get('hashtag');
        }

        this.loadVacationList();
      });
  }

  ngOnInit() {
    this.titleService.setTitle('My Vacations - VacationCounts');
  }

  private get sortClient(): boolean {
    // all sorting is client side for now - consider server for larger lists needing paging
    return true;
    // return this.filterTag || this.filterYear || this.filterHashtag;
  }

  private loadVacationList() {
    if (this.filterTag) {
      this.vacationObs$ = this.vacationService.getVacationsListByTag(this.filterTag);
    } else if (this.filterYear) {
      this.vacationObs$ = this.vacationService.getVacationsListByYear(this.filterYear);
    } else if (this.filterHashtag) {
      this.vacationObs$ = this.vacationService.getVacationsListByHashtag(this.filterHashtag);
    } else {
      // get all vacations sorted on the server
      // New: sort all client side to save on firebase hits/cost - until paging is needed?
      this.vacationObs$ = this.vacationService.getVacationsListByYear(undefined);
    }

    if (this.vacationSubs) {
      this.vacationSubs.unsubscribe();
    }
    this.vacationSubs = this.vacationObs$.subscribe(vacations => {
      console.log('get vacations collection from service');
      this.vacationSorted = vacations;
      if (this.filterTag === Tag.UPCOMING) {
        this.vacationService.currentSortMode = SortMode.DATE_ASC;
      }
      if (this.sortClient) {
        this.SortVacations();
      }
      this.changeDetection.detectChanges();
    },
      // TODO - display message that no vacations were received
      error => this.vacationSorted = null
    );
  }

  routeAddVacation() {
    this.router.navigateByUrl('vacations/add');
  }

  SortVacations() {
    // Sort vacations client side except for view all vacations where in the future paging might be added
    // For example show vacations from 5 years at a time sorted by date by default and only get all if view sorted by name
    // This avoids hitting Firestore for every change of sort order which is costly
    if (this.sortClient) {
      // Sort by name or date when vieiwng vacation list by year tag or hashtag - view all sorts on server
      switch (this.vacationService.currentSortMode) {
        case SortMode.NAME_ASC:
          this.vacationSorted.sort((v1: Vacation, v2: Vacation) => {
            const v1name = v1.name.toLocaleLowerCase();
            const v2name = v2.name.toLocaleLowerCase();
            if (v1name < v2name) {
              return -1;
            } else if (v1name > v2name) {
              return 1;
            }
            return 0;
          });
          break;
        case SortMode.NAME_DESC:
          this.vacationSorted.sort((v1: Vacation, v2: Vacation) => {
            const v1name = v1.name.toLocaleLowerCase();
            const v2name = v2.name.toLocaleLowerCase();
            if (v1name < v2name) {
              return 1;
            } else if (v1name > v2name) {
              return -1;
            }
            return 0;
          });
          break;
        case SortMode.DATE_ASC:
          this.vacationSorted.sort((v1: Vacation, v2: Vacation) => {
            if (v1.dateFrom < v2.dateFrom) {
              return -1;
            } else if (v1.dateFrom > v2.dateFrom) {
              return 1;
            }
            return 0;
          });
          break;
        case SortMode.DATE_DESC:
          this.vacationSorted.sort((v1: Vacation, v2: Vacation) => {
            if (v1.dateFrom < v2.dateFrom) {
              return 1;
            } else if (v1.dateFrom > v2.dateFrom) {
              return -1;
            }
            return 0;
          });
          break;
      }
    } else {
      // get all vacations which is sorted on the server - possibly to do pagination later
      this.loadVacationList();
    }
  }

  get fxCardViewLayout(): string {
    return (this.vacationService.currentViewMode === ViewMode.LIST) ? 'column' : 'row wrap';
  }

  get zeroVacations() {
    return document.getElementsByTagName('app-vc-card-grid').length === 0;
  }

  get currentViewMode(): ViewMode {
    return this.vacationService.currentViewMode;
  }

  onChangeViewMode(toggleButton: any) {
    // store the current view in the service for persistance in app session
      this.vacationService.currentViewMode = toggleButton;
  }

  get currentSortMode(): SortMode {
    return this.vacationService.currentSortMode;
  }

  onChangeSortMode(toggleButton: any) {
    // store the current sort order in the service for persistance in app session
    this.vacationService.currentSortMode = toggleButton;

    // Sort the list and refresh
    this.SortVacations();
    this.changeDetection.detectChanges();
  }

  ngOnDestroy() {
    if (this.navSubs) {
      this.navSubs.unsubscribe();
    }
    if (this.vacationSubs) {
      this.vacationSubs.unsubscribe();
    }
  }
}
