/*
DO NOT STORE THIS FILE IN GIT VERSION CONTROL REPOSITORY
THESE ARE STORED LOCALLY ONLY AND IN KEEPASS FOR SECURE SAFEKEEPING
LOADED INTO ENVIRONMENTS CONFIG FOR RUNTIME USAGE
*/
export namespace AccessCloud {
  export const fbkey_test = 'AIzaSyAX8qJ2Q4nPbBnSL0BuoqhBDalQUpli6OQ';
  export const fbkey_prod = 'AIzaSyA_uqE5iGa9a1CJmWDdgvolLwt29LL9Bos';
  export const fbkey_dev = 'AIzaSyCNJM5mRc4vp8-v-d-0QKIQXdwldqMvxJI';

  export const googleapi_test = 'AIzaSyCRFUbJKuF60GxHGq7hInlsqD9fxf0I0WU';
  export const googleapi_prod = 'AIzaSyBhHoMYSd0RD1HfNLS1mRNjmJIeCi6gQ0A';
  export const googleapi_dev = 'AIzaSyBnEvTWAJrBERrDGv2TSgrKhGzmp-JLhsg';
}
