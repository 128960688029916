import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vc-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss']
})
export class SigninPageComponent implements OnInit {

   // TODO secure this or move email signin to a separate hidden app page
   allowEmailSignin: boolean = true;

  constructor() { }

  ngOnInit() {
  }


}
