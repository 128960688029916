import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../auth/auth-guard.service';
import { VcVacationEditComponent } from './vc-vacation-edit/vc-vacation-edit.component';
import { VcVacationViewComponent } from './vc-vacation-view/vc-vacation-view.component';
import { VcListComponent } from './vc-list/vc-list.component';

const vacationRoutes: Routes = [
  { path: 'add', component: VcVacationEditComponent, canActivate: [AuthGuard] },
  { path: ':id',
  children: [
      { path: 'edit', component: VcVacationEditComponent, canActivate: [AuthGuard] },
      { path: '', component: VcVacationViewComponent, canActivate: [AuthGuard] }
  ]},
  // TODO: Optimize by combining /show routes as they cause the VacationList to be destroyed and recreated
  { path: 'show/domestic', data: { tag: 'domestic', title: 'Domestic' }, component: VcListComponent, canActivate: [AuthGuard] },
  { path: 'show/international', data: { tag: 'foreign', title: 'International' },
    component: VcListComponent, canActivate: [AuthGuard] },
  { path: 'show/favorites', data: { tag: 'top', title: 'Favorites' }, component: VcListComponent, canActivate: [AuthGuard] },
  { path: 'show/upcoming', data: { tag: 'upcoming', title: 'Upcoming' }, component: VcListComponent, canActivate: [AuthGuard] },
  { path: 'tag/:hashtag', data: { hashtag: 'custom', title: 'hashtag' }, component: VcListComponent, canActivate: [AuthGuard] },
  { path: '', data: { title: 'VacationCounts' }, component: VcListComponent, canActivate: [AuthGuard] }
  // NOTE: Year is a parameter for the vacations path, not a separate path
];

@NgModule({
  imports: [
    RouterModule.forChild(vacationRoutes)
  ],
  exports: [RouterModule],
  providers: [
  ]
})
export class VacationRoutingModule {}
