import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { CountryCodes } from '../shared/world-data';

/* Types of Destinations - Total = 13 icons
Enums are used for mapping string constants saved to database with display names
These types can be used for categorization as well as displaying icons
and for generating stats and other info - extend as needed but never remove
export enum DestinationType_OLD {
  PLACE = 'Destination',  // V1.0
  COUNTRY = 'Country',  // V1.0
  CITY = 'City',  // V1.0
  TOWN = 'Town',  // V1.1
  PARK = 'Park',  // V1.0
  BEACH = 'Beach',  // V1.0
  ISLAND = 'Island',  // V1.0
  HOTEL = 'Hotel',  // V1.1
  NATURE = 'Nature',  // V1.1
  MOUNTAIN = 'Mountain',  // V1.0
  REGION = 'Region',  // V1.0
  ATTRACTION = 'Attraction',  // V1.0
  FOOD = 'Food',  // V1.1
  HISTORIC = 'Historic',  // V1.0
  PORT = 'Port',  // V1.0
  DRIVE = 'Drive',  // V1.0
  EVENT = "Event"  // V1.0
} */

export enum DestinationType {
  PLACE = 'General',  // V1.0 - map: PORT
  CITY = 'City',  // V1.0
  TOWN = 'Town',  // V1.1
  REGION = 'Region',  // V1.0 - map: COUNTRY
  PARK = 'Park',  // V1.0
  NATURE = 'Outdoors',  // V1.1 - map: ISLAND, MOUNTAIN, BEACH - SPORT, LAKE
  ATTRACTION = 'Attraction',  // V1.0
  HOTEL = 'Place to Stay',  // V1.1
  HISTORIC = 'Historic',  // V1.0
  DRIVE = 'Transportation',  // V1.0
  FOOD = 'Food & Drink',  // V1.1
  ARTS = 'Arts & Culture',  // V1.0
  EVENT = 'Event',  // V1.0
  NIGHT = 'Nightlife',  // V1.0
  SHOPPING = 'Shopping',  // V1.0
  HEALTH = 'Wellness'  // V1.0
}

const DestinationTypeIcons: Map<string, string> = new Map<string,string>(
  [
    ['PLACE', 'dtype:place'],
    ['CITY', 'dtype:city'],
    ['TOWN', 'dtype:town'],
    ['REGION', 'dtype:region'],
    ['PARK', 'dtype:park'],
    ['NATURE', 'dtype:nature'],
    ['ATTRACTION', 'dtype:attraction'],
    ['HOTEL', 'dtype:hotel'],
    ['HISTORIC', 'dtype:historic'],
    ['DRIVE', 'dtype:drive'],
    ['FOOD', 'dtype:fooddrink'],
    ['ARTS', 'dtype:arts'],
    ['EVENT', 'dtype:event'],
    ['NIGHT', 'dtype:nightlife'],
    ['SHOPPING', 'dtype:shopping'],
    ['HEALTH', 'dtype:health'],
  ]
);

export class DestinationFS {
  // model properties
  vacationId: string;
  sort: number;
  year: string;
  name: string;
  countryCode: string;
  type: string;
  description: string;
  map?: firebase.firestore.GeoPoint;

  // meta-_data
  created?: any; // stores firestore server timestamp
  changed?: any; // stores firestore server timestamp
  // deleted?: any; // stores firestore server timestamp

  // default value constructor
  constructor() {
    this.vacationId = null;
    this.sort = 0;
    this.year = '';
    this.name = '';
    this.countryCode = 'US';
    this.type = 'PLACE';
    this.description = '';
    this.map = null;
  }
}

export class Destination {
  private _id: string = undefined; // Firebase key
  private _data: DestinationFS; // interface model _data for Firestore

  private _destIcon: string = '';

  // Data to track add/edit/delete status during Vacation edit mode
  private _added = false;
  private _edited = false;
  private _deleted = false;

  constructor(destData?: DestinationFS) {
    // Initialize the firestore data object to default values
    this._data = new DestinationFS();

    if (destData) {
      this.SetData(destData);
    }
  }

  get data(): DestinationFS {
    return this._data;
  }

  private SetData(data: DestinationFS) {
    // When set data from Firestore, apply business logic to not allow bad values and use defaults for missing
    // Each set function should check for null or undefined and min requirements for setting data member
    this.vacationId = data.vacationId;
    this.sort = data.sort;
    this.year = data.year;
    this.name = data.name;
    this.type = data.type;
    this.countryCode = data.countryCode;
    this.description = data.description;
    this.map = data.map;

    this._destIcon = DestinationTypeIcons.get(this.type);
    if (!this._destIcon || this._destIcon.length == 0) {
      this._destIcon = 'dtype:place';
    }
  }

  get id(): string {
    return this._id;
  }
  set id(id: string) {
    this._id = id;
  }

  get sort(): number {
    return this.data.sort;
  }
  set sort(sort: number) {
    this._data.sort = sort;
  }

  get year(): string {
    return this.data.year;
  }
  set year(year: string) {
    this._data.year = year;
  }

  get vacationId(): string {
    return this._data.vacationId;
  }
  set vacationId(vacationId: string) {
    this._data.vacationId = vacationId;
  }

  get name(): string {
    return this._data.name;
  }
  set name(name: string) {
    if (name && name.length > 0) {
      this._data.name = name;
    }
  }

  get countryCode(): string {
    return this._data.countryCode;
  }
  set countryCode(countryCode: string) {
    if (countryCode) {
      this._data.countryCode = countryCode;
    }
  }
  get countryName(): string {
    return CountryCodes[this.countryCode].name;
  }

  get type(): string {
    return this._data.type;
  }
  set type(type: string) {
    if (type) {
      // getting back a string version of the enum key from DB - convert to enum type
      this._data.type = type;
    }
  }

  get description(): string {
    return this._data.description;
  }
  set description(description: string) {
    if (description && description.length > 0) {
      this._data.description = description;
    }
  }

  get map(): firebase.firestore.GeoPoint {
    return this._data.map;
  }
  set map(map: firebase.firestore.GeoPoint) {
    if (map) {
      this._data.map = map;
    }
  }

  public getDestIcon() {
    return this._destIcon;
  }

  set added(added: boolean) {
    this._added = added;
  }
  get added(): boolean {
    return this._added;
  }
  set edited(edited: boolean) {
    this._edited = edited;
  }
  get edited(): boolean {
    return this._edited;
  }
  set deleted(deleted: boolean) {
    this._deleted = deleted;
  }
  get deleted(): boolean {
    return this._deleted;
  }

}
