import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

import { VcListComponent } from './vc-list/vc-list.component';
import { VcVacationViewComponent } from './vc-vacation-view/vc-vacation-view.component';
import { VcVacationCardGridComponent } from './vc-vacation-card/vc-card-grid.component';
import { VcVacationEditComponent } from './vc-vacation-edit/vc-vacation-edit.component';
import { VacationRoutingModule } from './vacation-routing.module';
import { VcDestinationEditComponent } from './vc-vacation-edit/vc-destination-edit/vc-destination-edit.component';
import { VcDestinationViewComponent } from './vc-vacation-view/vc-destination-view.component';
import { VcHashtagEditorComponent } from './vc-vacation-edit/vc-hashtag-editor/vc-hashtag-editor.component';
import { VcLinkEditComponent } from './vc-vacation-edit/vc-link-edit/vc-link-edit.component';
import { VcLinkViewComponent } from './vc-vacation-view/vc-link-view.component';
import { VCMaterialModule } from '../design/vcmaterial.module';
import { VcConfirmDeleteComponent } from './vc-vacation-edit/vc-confirm-delete';

@NgModule({
  declarations: [
    VcListComponent,
    VcVacationViewComponent,
    VcVacationCardGridComponent,
    VcVacationEditComponent,
    VcDestinationEditComponent,
    VcDestinationViewComponent,
    VcHashtagEditorComponent,
    VcLinkEditComponent,
    VcLinkViewComponent,
    VcConfirmDeleteComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    VCMaterialModule,
    SharedModule,
    VacationRoutingModule
  ],
  exports: [
  ],
  entryComponents: [ VcConfirmDeleteComponent ]
})
export class VacationModule {}
