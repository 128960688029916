import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';

import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { filter } from 'rxjs/operators';
import { StatsSummary } from './model/stats.model';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { UserAccountService } from './services/useraccount.service';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'VacationCounts';
  pageTitle = 'VacationCounts';
  private gaTracking = true;

  @ViewChild('vcsidebar') sidebar: MatSidenav;

  navSubs: Subscription = null;
  statsVSub: Subscription = null;
  stats: StatsSummary = new StatsSummary();

  private routeTitle = 'title';
  private routeHashtag = 'hashtag';

  hrefHelp: string = environment.vc.helpUrl;
  hrefSupport: string = environment.vc.supportUrl;
  hrefShare: string = environment.vc.shareUrl;

  exportOn = false;

  mobileQuery;
  private mobileQueryListener: () => void;

  constructor(private readonly router: Router,
              private route: ActivatedRoute,
              private gtag: Gtag, // import and inject to acivate Google Analytics
              public authService: AuthService,
              public userService: UserAccountService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // Changed mobileQuery to non-typed and addEventListener to addListener even though it is deprecated
    // iPhone IOS Safari does not always support the new function so crashes the app
    // chrome://inspect log error addEventListener is not a function
    // addEventListener supported on newer browsers
    try {
      this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    } catch (e1) {
      try {
        this.mobileQuery.addListener(this.mobileQueryListener);
      } catch (e2) {
        console.log('MobileQueryListener failed to listen to changes event');
      }
    }

    // this doesn't work with the sprite exported Flaticon collection of icons
    // the viewBox values do not get output to html so the location and size are messed up
    // Need to manually create the file format similar to icomoon with multiple svg elements
    this.matIconRegistry
      .addSvgIconSetInNamespace('dtype',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/types/destinationtypes.svg'));

    this.matIconRegistry
      .addSvgIcon('add',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/vacation-add.svg'))
      .addSvgIcon('view',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/vacation-view.svg'))
      .addSvgIcon('edit',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/vacation-edit.svg'))
      .addSvgIcon('delete',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/vacation-delete.svg'))
      .addSvgIcon('listitem-add',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/destination-add.svg'))
      .addSvgIcon('listitem-delete',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/destination-delete.svg'))
      .addSvgIcon('link-add',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/link-add.svg'))
      .addSvgIcon('link-delete',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/link-delete.svg'))
      .addSvgIcon('item-move-up',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/item-move-up.svg'))
      .addSvgIcon('item-move-down',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/item-move-down.svg'))
      .addSvgIcon('menu-user',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/menu-user.svg'))
      .addSvgIcon('dashboard',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/dashboard-chart.svg'))
      .addSvgIcon('vacation-list',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/vacation-postcard.svg'))
      .addSvgIcon('explore',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/search-travel.svg'))
      .addSvgIcon('days-off',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/days-off.svg'))
      .addSvgIcon('view-List',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/view-list.svg'))
      .addSvgIcon('view-Grid',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/view-grid.svg'))
      .addSvgIcon('sort-NameAsc',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/sort-name-asc.svg'))
      .addSvgIcon('sort-NameDesc',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/sort-name-desc.svg'))
      .addSvgIcon('sort-DateAsc',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/sort-date-asc.svg'))
      .addSvgIcon('sort-DateDesc',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu/sort-date-desc.svg'))
      .addSvgIcon('tripadvisor',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/brands/tripadvisor.svg'))
      .addSvgIcon('google',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/google.svg'));

  }

  ngOnInit(): void {
    this.navSubs = this.router.events.pipe(
      filter( event => event instanceof NavigationEnd))
      .subscribe(event => {
        // Update the app state include page title
        this.updatePageTitle();

        // scroll to the top just in case the link they clicked was further down the page
        // window.scrollTo(0,0); - doesn't work maybe due to wrapper around sidenav and content
        document.getElementsByTagName('app-header')[0].scrollIntoView();
      });
  }

  ngAfterViewInit(): void {
      // Close the sidebar if navigating via sidebar router link and in mobile view
      if (this.mobileQuery && this.mobileQuery.matches) {
        this.sidebar.close();
      }
  }

  hasPageName(): boolean {
    return (this.route.snapshot.firstChild && this.route.snapshot.firstChild.data[this.routeTitle]);
  }

  updatePageTitle(): void {
    if (this.hasPageName()) {
      // Custom behavior for a few pages
      // TODO see if this logic can be moved into the activated component
      if (this.route.snapshot.firstChild.data[this.routeHashtag]) {
        let lastChild: ActivatedRouteSnapshot = this.route.snapshot.firstChild;
        while (lastChild.firstChild) {
          lastChild = lastChild.firstChild;
        }
        this.pageTitle = ' #' + lastChild.url.pop().path;
      } else if (this.route.snapshot.firstChild.paramMap.has('year')) {
        this.pageTitle = this.route.snapshot.firstChild.paramMap.get('year') + ' Vacations';
      } else {
        this.pageTitle = this.route.snapshot.firstChild.data[this.routeTitle];
      }

      // If not in mobile view - add VacationCounts to the head of the page title
      if (!this.mobileQuery.matches && this.pageTitle !== this.title) {
        this.pageTitle = this.title + ' - ' + this.pageTitle;
      }
    } else {
      this.pageTitle = this.title;
    }
  }

  routeAddVacation(): void {
    this.router.navigateByUrl('vacations/add');
  }

  onLogout(): void {
    this.authService.logout()
      .then(() => {
        this.router.navigate(['']);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode + ': ' + errorMessage);
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);

    if (this.navSubs) {
      this.navSubs.unsubscribe();
    }
  }

}
