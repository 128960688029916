import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CountryCodes } from '../../../shared/world-data';
import { DestinationType, Destination } from '../../../model/destination.model';
import { DestinationService } from '../../../services/destinations.service';
import { UserAccountService } from 'src/app/services/useraccount.service';

@Component({
  selector: 'app-vc-destination-edit',
  templateUrl: './vc-destination-edit.component.html',
  styleUrls: ['./vc-destination-edit.component.scss']
})
export class VcDestinationEditComponent implements OnInit, OnDestroy {

  countryKeys: string[];
  countries = CountryCodes; // store a reference to the type to look up its value for binding to the option elements
  destTypes = DestinationType; // store a reference to the type to look up its value for binding to the option elements
  destKeys: string[];

  private vacationId: string;

  // store the loaded destination ids to delete on save to create new documents
  private destinationIds: string[] = [];

  dForm: FormGroup;
  destsSub: Subscription;
  private destinations: Destination[] = [];
  statusMsg: string;

  constructor(private formBuilder: FormBuilder,
    private readonly destinationService: DestinationService,
    private readonly userService: UserAccountService) {
    this.countryKeys = Object.keys(CountryCodes);
    this.destKeys = Object.keys(DestinationType);
  }

  ngOnInit() {
    this.dForm = this.formBuilder.group({
      destinationsForm: this.formBuilder.array([])
    });
  }

  noKeySorting() {
    return 0;
  }

  setVacationId(existingVacation: boolean, vid: string) {
    this.vacationId = vid;
    if (existingVacation) {
      // For a new vacation object don't get the links because there are none
      if (this.vacationId && this.vacationId.length > 0) {
        this.destsSub = this.destinationService.getDestinationsForVacation(this.vacationId)
          .subscribe((destsColl) => {
            console.log('get destinations collection from Firestore');
            this.destinations = destsColl;
            this.fillDestsData();
          });
      }
    }
  }

  ngOnDestroy() {
    if (this.destsSub) {
      this.destsSub.unsubscribe();
    }
  }

  get destinationsForm(): FormArray {
    return <FormArray>this.dForm.get('destinationsForm');
  }

  get oldDestinationIds(): string[] {
    return this.destinationIds;
  }

  private fillDestsData() {
    // Store the list of destination ids in case they need to be deleted on update existing vacation
    this.destinationIds = this.destinations.map(dest => dest.id);

    // Create a form group for each destination object and add it to the parent destinations form array
    const destsFGs = this.destinations.map(dest => {
      return this.addDestinationItem(dest);
    });

    if (destsFGs && destsFGs.length > 0) {
      const destsFormArray = this.formBuilder.array(destsFGs);
      this.dForm.setControl('destinationsForm', destsFormArray);
    }
  }

  addNewDestination() {
    if (!this.maxDestinations()) {
      // scroll the new formgroup into view by scrolling up the final field of the previous
      // this avoids having to use settimeout to wait until the new formgroup is added to get by id
      /* const lastFGIndex = this.destinationsForm.length - 1;
      const previousDestName: HTMLElement = document.getElementById("dest-" + lastFGIndex.toString() + "-description");
      if (previousDestName) {
        previousDestName.scrollIntoView();
      } */

      // Set the country to the same as the previous
      let cc = null;
      if (this.destinationsForm.controls.length > 0) {
        const destForm = (<FormGroup>this.destinationsForm.controls[this.destinationsForm.controls.length - 1]);
        cc = destForm.get('countryCode').value;
      }


      this.destinationsForm.push(this.addDestinationItem(null, cc));
    }
  }

  addDestinationItem(dest?: Destination, countryCode?: string): FormGroup {
    // If the user adds a link create a new blank one
    if (!dest) {
      dest = new Destination();
      // The id will be blank which means it is a new destination
      if (countryCode) {
        dest.countryCode = countryCode;
      }
    }

    return this.formBuilder.group({
      name: [dest.name, [Validators.required, Validators.minLength(4), Validators.maxLength(75)]],
      countryCode: [dest.countryCode, Validators.required],
      type: [dest.type, Validators.required],
      description: [dest.description, [Validators.maxLength(250)]],
      id: [dest.id]
    });
  }

  itemMoveUp(index: number) {
    const move = this.destinationsForm.controls.splice(index, 1);
    if (move.length > 0) {
      this.destinationsForm.controls.splice(index - 1, 0, ...move);
      // set all items above as dirty so they are resaved in new sort order
      for (let i = index; i >= 0; i--) {
        this.destinationsForm.controls[i].markAsDirty();
      }
    }
  }

  itemMoveDown(index: number) {
    const move = this.destinationsForm.controls.splice(index, 1);
    if (move.length > 0) {
      this.destinationsForm.controls.splice(index + 1, 0, ...move);
      // set all items below as dirty so they are resaved in new sort order
      for (let i = index; i < this.destinationsForm.controls.length; i++) {
        this.destinationsForm.controls[i].markAsDirty();
      }
    }
  }

  get dataValid(): boolean {
    return this.dForm.valid;
  }

  maxDestinations(): boolean {
    if (this.destinations) {
      if (this.destinationsForm.length >= this.userService.maxDestinations) {
        return true;
      }
    }
    return false;
  }

  getDestinationDataToSave(): Destination[] {
    // collect all the destination data to send with the edited vacation object to store in Firestore
    // this includes edited destination, added destination, and deleted destination
    let sortIndex = 1;
    (<FormGroup[]>this.destinationsForm.controls).forEach(destForm => {
      let eachDest: Destination = this.destinations.find(destObj => destObj.id === destForm.get('id').value);

      // a destination can be added then deleted so check for both for new destinations
      if (!eachDest) {
        // this is a new destination to add to the list
        eachDest = new Destination();
        this.setDestinationDataValues(destForm, eachDest);
        // set the vacation id to associate this new destination with its parent object
        eachDest.vacationId = this.vacationId;
        eachDest.added = true;
        this.destinations.push(eachDest);
      } else if (destForm.dirty) {
        // Mark the Destination object as changed and set the values to save
        this.setDestinationDataValues(destForm, eachDest);
        eachDest.edited = true;
      }
      // otherwise the destination was not dirty or it was deleted so the values do not matter

      // update the sort order for each element in the form array to save in the same order as displayed
      eachDest.sort = sortIndex++;
    });

    return this.destinations;
  }

  setDestinationDataValues(destsForm: FormGroup, thisDest: Destination) {
    thisDest.name = destsForm.get('name').value;
    thisDest.countryCode = destsForm.get('countryCode').value;
    thisDest.type = destsForm.get('type').value;
    thisDest.description = destsForm.get('description').value;
  }

  deleteDestination(destIndex: number) {
    // the array index of the control to delete is passed back
    // Get the hidden input id value from the formgroup
    const destId: string = this.destinationsForm.controls[destIndex].get('id').value;

    // mark the Link object as deleted so it can be deleted from the Firestore Collection
    const deleteDestIndex = this.destinations.findIndex(destObj => destObj.id === destId);
    if (deleteDestIndex > -1) {
      this.destinations[deleteDestIndex].deleted = true;
    }
    // otherwise it is a new destination form group added and then deleted so ignore

    // Remove the control from the array
    this.destinationsForm.removeAt(destIndex);
  }
}

