import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private readonly fireFunctions: AngularFireFunctions,
              private readonly auth: AuthService ) { }

  exportAllUserData(): Promise<{ downloadUrl: string }> {
    const fnExportData = this.fireFunctions.httpsCallable('exportUserVacationData');
    return fnExportData({ currentUser: this.auth.getcurrentUserId() }).toPromise<{ downloadUrl: string }>();
  }
}
