import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vc-confirm-delete',
  template: `<h2 mat-dialog-title>Are you sure you want to delete?</h2>
            <mat-dialog-content>
              <div class="body-1">{{ passedData.warningMsg }}</div>
              <div class="body-2">{{ passedData.itemName }}</div>
            </mat-dialog-content>
            <mat-dialog-actions>
              <button mat-raised-button color="warn" [mat-dialog-close]="true">Yes</button>
              <button mat-raised-button [mat-dialog-close]="false">No</button>
            </mat-dialog-actions>`
})
export class VcConfirmDeleteComponent {
  constructor(private dialogRef: MatDialogRef<VcConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public passedData: any) {
    }
}
