import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Link, LinkType, LinkTypeIcons } from '../../../model/link.model';
import { Subscription } from 'rxjs';
import { LinksService } from '../../../services/links.service';
import { urlRegex } from '../../../shared/validators/validateUrl';
import { UserAccountService } from 'src/app/services/useraccount.service';

@Component({
  selector: 'app-vc-link-edit',
  templateUrl: './vc-link-edit.component.html',
  styleUrls: ['./vc-link-edit.component.scss']
})
export class VcLinkEditComponent implements OnInit, OnDestroy {

  private vacationId: string;

  lForm: FormGroup;
  linksSub: Subscription;
  private links: Link[] = [];
  statusMsg: string;

  linktypes = LinkType; // store a reference to the type to look up its value for binding to the option elements
  linkTypeIcons = LinkTypeIcons;

  constructor(private formBuilder: FormBuilder,
              private readonly userService: UserAccountService,
              private readonly linkService: LinksService) {
  }

  ngOnInit() {
    this.lForm = this.formBuilder.group({
      linksForm: this.formBuilder.array([])
    });
  }

  noKeySorting() {
    return 0;
  }

  setVacationId(existingVacation: boolean, vid: string) {
    this.vacationId = vid;
    if (existingVacation) {
      this.linksSub = this.linkService.getVacationLinks(this.vacationId).subscribe( (linksColl) => {
        console.log('get links collection from Firestore');
        this.links = linksColl;
        this.fillLinksData();
      });
    }
  }

  ngOnDestroy() {
    if (this.linksSub) {
      this.linksSub.unsubscribe();
    }
  }

  get linksForm(): FormArray {
    return <FormArray>this.lForm.get('linksForm');
  }

  fillLinksData() {
    // Create a form group for each link object and add it to the parent link form array
    const linkFGs = this.links.map(link => {
      return this.addLinkItem(link);
    });

    if (linkFGs && linkFGs.length > 0) {
      const linkFormArray = this.formBuilder.array(linkFGs);
      this.lForm.setControl('linksForm', linkFormArray);
    }
  }

  getLinkIds(): string[] {
    // return the list of link IDs retrieved from the collection
    // this is used for deleting the existing saved links while deleting the parent vacation
    // any new links added during this edit session would not have been added to Firestore yet
    const linkIds: string[] = [];
    this.links.forEach(link => {
      linkIds.push(link.id);
    });
    return linkIds;
  }

  maxLinks(): boolean {
    if (this.links) {
      if (this.linksForm.length >= this.userService.maxLinks) {
        return true;
      }
    }
    return false;
  }

  delete(linkIndex: number) {
    // the array index of the control to delete is passed back
    // Get the hidden input id value from the formgroup
    const linkId: string = this.linksForm.controls[linkIndex].get('id').value;

    // mark the Link object as deleted so it can be deleted from the Firestore Collection
    const deleteLinkIndex = this.links.findIndex(linkObj => linkObj.id === linkId);
    if (deleteLinkIndex > -1) {
      this.links[deleteLinkIndex].deleted = true;
    }
    // otherwise it is a new link form group added and then deleted so ignore

    // Remove the control from the array
    this.linksForm.removeAt(linkIndex);
  }

  addNewLink() {
    if (!this.maxLinks()) {
      // scroll the new formgroup into view by scrolling up the final field of the previous
      // this avoids having to use settimeout to wait until the new formgroup is added to get by id
      /* const lastFGIndex = this.linksForm.length - 1;
      const previousLinkName: HTMLElement = document.getElementById("links-" + lastFGIndex.toString() + "-linkurl");
      if (previousLinkName) {
        previousLinkName.scrollIntoView();
      } */

      this.linksForm.push(this.addLinkItem());
    }
  }

  addLinkItem(link?: Link): FormGroup {

    // If the user adds a link create a new blank one
    if (!link) {
      link = new Link();
      // The id will be blank which means it is a new link
    }

    return this.formBuilder.group({
      name: [link.name, [ Validators.required, Validators.maxLength(50) ]],
      url:  [link.url, [ Validators.required, Validators.maxLength(255), Validators.pattern(urlRegex)] ],
      linktype: [link.linktype, Validators.required],
      id: [link.id]
    });
  }

  itemMoveUp(index: number) {
    const move = this.linksForm.controls.splice(index, 1);
    if (move.length > 0) {
      this.linksForm.controls.splice(index - 1, 0, ...move);
      // set all items above as dirty so they are resaved in new sort order
      for (let i = index; i >= 0; i--) {
        this.linksForm.controls[i].markAsDirty();
      }
    }
  }

  itemMoveDown(index: number) {
    const move = this.linksForm.controls.splice(index, 1);
    if (move.length > 0) {
      this.linksForm.controls.splice(index + 1, 0, ...move);
      // set all items below as dirty so they are resaved in new sort order
      for (let i = index; i < this.linksForm.controls.length; i++) {
        this.linksForm.controls[i].markAsDirty();
      }
    }
  }

  get dataValid(): boolean {
    return this.lForm.valid;
  }

  getLinkDataToSave(): Link[] {
    // collect all the link data to send with the edited vacation object to store in Firestore
    // this includes edited links, added links, and deleted links
    let sortIndex = 1;
    (<FormGroup[]>this.linksForm.controls).forEach(linkForm => {
      let eachLink: Link = this.links.find(linkObj => linkObj.id === linkForm.get('id').value);

      // a link can be added then deleted so check for both for new links
      if (!eachLink) {
        // this is a new link to add to the list
        eachLink = new Link();
        this.setLinkDataValues(linkForm, eachLink);
        eachLink.added = true;
        this.links.push(eachLink);
      } else if (linkForm.dirty) {
        // Mark the Link object as changed and set the values to save
        this.setLinkDataValues(linkForm, eachLink);
        eachLink.edited = true;
      }
      // otherwise the link was not dirty or it was deleted so the values do not matter

      // update the sort order for each element in the form array to save in the same order as displayed
      eachLink.sort = sortIndex++;
    });

    return this.links;
  }

  setLinkDataValues(linkForm: FormGroup, thisLink: Link) {
    thisLink.name = linkForm.get('name').value;

    // add in the http if not included for saving to the db
    thisLink.url = linkForm.get('url').value;
    if (!thisLink.url.startsWith('http://') && !thisLink.url.startsWith('https://')) {
      thisLink.url = 'http://' + thisLink.url;
    }

    thisLink.linktype = linkForm.get('linktype').value;
  }
}
