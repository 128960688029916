import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Link, LinkType } from '../../model/link.model';
import { Observable, Subscription } from 'rxjs';
import { LinksService } from '../../services/links.service';

@Component({
  selector: 'app-vc-link-view',
  styleUrls: ['./vc-vacation-view.component.scss'],
  template: `
  <div *ngIf="(linksObs$ | async) as linksList">
    <ng-container *ngIf="linksList?.length > 0; else zeroLinks">
      <mat-list>
        <mat-list-item *ngFor="let link of linksList" class="vc-listiconbox">
          <mat-icon matListIcon class="v-iconbox" fxFlexAlign="baseline">{{ link.linkClass }}</mat-icon>
          <h4><a href="{{ link?.url }}" target="_blank" rel="noopener">{{ link?.name }}</a></h4>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </div>
  <ng-template #zeroLinks>
    <div fxLayout="row">
      <mat-icon class="v-iconbox" fxFlexAlign="baseline">bookmark</mat-icon>
    </div>
  </ng-template>
  `
})
export class VcLinkViewComponent implements OnInit {

  @Input() vacationId: string;

  linksObs$: Observable<Link[]>;

  constructor(private readonly linkService: LinksService) {
  }

  ngOnInit() {
    this.linksObs$ = this.linkService.getVacationLinks(this.vacationId);
  }

}
