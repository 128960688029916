import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Freemium } from 'src/app/auth/freemium';

@Component({
  selector: 'app-vc-upgrade',
  templateUrl: './vc-upgrade.component.html',
  styleUrls: ['./vc-upgrade.component.scss']
})
export class VcUpgradeComponent implements OnInit {

  upgradeUrl = environment.vc.upgradeUrl;
  maxVacationLimit = Freemium.MAX_VACATIONS;

  constructor() { }

  ngOnInit() {
  }

}
