import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router, RouterLinkActive } from '@angular/router';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() mobileTopMargin = 0;

  currentYear = new Date().getFullYear().toString();

  constructor() { }

  ngOnInit() {
  }
}
