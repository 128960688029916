import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from '../../../../environments/environment';

import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-vc-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  @Input() showExplainer = false;
  @Input() showLogin = true;
  @Input() signinMessage = 'Login to the App';

  allowEmail: boolean = environment.vc.allowEmail;

  agreeTerms = false;
  href_terms = environment.vc.termsUrl;
  href_privacy = environment.vc.privacyUrl;
  appinfoUrl = environment.vc.appinfoUrl;

  constructor(private authService: AuthService,
              private router: Router) { }

  ngOnInit() {
  }

  onSigninEmail(form: NgForm) {
    const email = form.value.email;
    const password = form.value.password;

    this.authService.signInUserEmail(email, password)
      .then(res => {
        // TODO - should this navigate to the requested url if any
        this.router.navigate(['']);
      })
      .catch(function(error) {
          // TODO - Change from console log to write database log to prevent hacking
          console.log(error.code + ': ' + error.message);
      });
  }

  loginGoogleAccount() {
    this.authService.signinUserFirebaseAuthGoogle();
  }

  loginGoogleAccountNew() {
    this.authService.signinUserFirebaseAuthGoogle();
  }
}
