import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../auth/auth.service';
import { Subscription, Observable } from 'rxjs';
import { WordpressService } from '../../services/wordpress.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  isLoggedIn = false;
  // TODO - display this for new users by hide after x days?
  isNewUser = true;
  private userSubscription: Subscription = null;

  blogPosts$: Observable<any[]> = null;
  appNews$: Observable<any> = null;

  constructor(private titleService: Title,
              private authService: AuthService,
              private wp: WordpressService,
              private http: HttpClient) {

      this.userSubscription = this.authService.userLoginChangeEvent.subscribe(() => {
        this.updateLoginState();
      });
    }

  ngOnInit(): void {
    this.titleService.setTitle('VacationCounts Web App');
    // Load in the latest 3 blog posts to display on the home page
    this.blogPosts$ = this.wp.getPosts(3);

    // OLD way was to get the page but too slow so turn to static html file
    // <div class="vc-blog-app-news" [innerHTML]="news.content.rendered"></div>
    // Page ID 2868 contains the text for app release notes, news, tips
    // The rendered html doesn't include the styles so write in simple text
    // this.appNews$ = this.wp.getPage(2868);
    this.appNews$ = this.http.get('assets/content/news.html', {responseType: 'text'});
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  showHide(): void {
    this.isLoggedIn = !this.isLoggedIn;
  }

  private updateLoginState(): void {
    this.isLoggedIn = this.authService.isAuthenticated();
  }

}
