import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { AuthService } from '../auth/auth.service';
import { Destination, DestinationFS } from '../model/destination.model';

@Injectable({
  providedIn: 'root'
})
export class DestinationService {

  private destinationColl: AngularFirestoreCollection<DestinationFS>;
  private destinationList$: Observable<Destination[]>;

  constructor(private readonly firestoreDB: AngularFirestore, private readonly auth: AuthService) {
  }

  getDestinationsForVacation(vacationId: string): Observable<Destination[]> {

    console.log('get destinations collection for vacation observable');

    // Get the list of destinations for a vacation
    this.destinationColl = this.firestoreDB.collection('users').doc(this.auth.getcurrentUserId()).
      collection<DestinationFS>('destinations', ref => ref.where('vacationId', '==', vacationId).orderBy('sort'));

    // Subscribe to the value changes from the observable object to always have the latest vacation list data
    // Since the keys are not stored in the data object, SnapshotChanges is required to get the id from the metadata
    // this is the code to be used to add it to the IVacation object after async data is retrieved from Firestore
    this.destinationList$ = this.destinationColl.snapshotChanges().pipe(map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as DestinationFS;
        const dest = new Destination(data);
        dest.id = a.payload.doc['id'];
        return dest;
      });
    }));
    return this.destinationList$;
  }

  deleteDestinationsForVacation(batch: firebase.firestore.WriteBatch, uid: string, oldDestinationIds: string[]) {
    // There is no delete collection/query so must delete each old Destination document one by one in the batch operation
    oldDestinationIds.forEach(destId => {
      const destDoc = this.firestoreDB.collection('users').doc(uid).collection('destinations').doc(destId);
      batch.delete(destDoc.ref);
    });
  }

  addDestinations(batch: firebase.firestore.WriteBatch, uid: string, destArray: Destination[]) {
    destArray.forEach(dest => {

      // set the created and changed on date timestamp values using server time functions
      dest.data.created = firebase.firestore.FieldValue.serverTimestamp();
      dest.data.changed = dest.data.created;

      // Add each destination object to the destinations collection under the user id
      // Each destination has a vacationID property to link it to its parent vacation
      const did = this.firestoreDB.createId();
      const destDoc: AngularFirestoreDocument<DestinationFS> =
        this.firestoreDB.collection('users').doc(uid).collection('destinations').doc(did);

      // Convert the custom object to a plan Object to be safe and more compatible with Firestore SDK
      const destData = {};
      Object.assign(destData, dest.data);
      batch.set(destDoc.ref, destData);
    });
  }

  updateDestinations(batch: firebase.firestore.WriteBatch, uid: string, destArray: Destination[]) {
    const destColl: AngularFirestoreCollection<DestinationFS> =
        this.firestoreDB.collection('users').doc(uid).collection('destinations');

    // if any deleted then sort order will change so save all destinations that follow in order not just edited
    let anyDeleted = false;

    destArray.forEach(dest => {
      // if the destination was added as new and not deleted during the same edit session, add it as new
      const destData = {};
      if (dest.added && !dest.deleted) {
        dest.data.created = firebase.firestore.FieldValue.serverTimestamp();
        dest.data.changed = dest.data.created;

        Object.assign(destData, dest.data);
        const did = this.firestoreDB.createId();
        batch.set(destColl.doc(did).ref, destData);

      } else if (dest.deleted) {
        anyDeleted = true;
        batch.delete(destColl.doc(dest.id).ref);

      } else if (dest.edited || anyDeleted) {
        if (dest.edited) {
          // update the changed on date timestamp values using server time functions
          dest.data.changed = firebase.firestore.FieldValue.serverTimestamp();
        }
        Object.assign(destData, dest.data);
        batch.update(destColl.doc(dest.id).ref, destData);
      }
    });
  }

}
