import { DestinationFS } from './destination.model';
import { CurrencyTypes } from './finance.model';

// Firestore _data models guidelines:
// Each custom class must have a public constructor that takes no arguments.
// In addition, the class must include a public getter for each property.
export class VacationFS {
  // model properties
  name: string;
  description?: string;
  notes?: string;
  dateFrom?: string;
  dateTo?: string;
  year?: string;
  domestic?: boolean;
  foreign?: boolean;
  top?: boolean;
  hashtags?: { [hash:string]: boolean };
  profilePhoto?: string;
  cost?: number;
  currency?: CurrencyTypes;
  destinations: DestinationFS[] = [];

  // meta-_data
  created?: any; // stores firestore server timestamp
  changed?: any; // stores firestore server timestamp
  deleted?: any; // stores firestore server timestamp

  // default value constructor
  constructor() {
    this.name = '';
    this.description = '';
    this.notes = '';
    this.dateFrom = undefined;
    this.dateTo = undefined;
    this.year = '';
    this.domestic = false;
    this.foreign = false;
    this.top = false;
    this.hashtags = undefined;
    this.profilePhoto = '';
    this.cost = 0;
    this.currency = CurrencyTypes.USD;
  }
}

/* Vacation Class
*/
export class Vacation {

  public static FB_DATE_STR = 'YYYY-MM-DD';
  public static FIELD_BLANK = 'None';
  public static FIELD_DATEFROM_BLANK = 'Not entered';
  public static FIELD_DATETO_BLANK = 'Not entered';

  // Need to set timezone to UTC to ignore treatment of time when converting from date string to date obj to str
  // if date with 00:00:00 time is set as local time zone on users browser, it can subtract hours and become yesterday!
  public static CalDateOpt: any = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'};
  public static ShortDateOpt: any = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' };

  // class members
  private _id: string; // Firebase key
  private _data: VacationFS; // interface model _data for Firestore

  hashtagsList: string[] = [];

  // TODO - consider set for _data in model to create new clean interface and set individual members
  // so that all members have a non undefined value and to enforce rules as app _data schema changes


  constructor(vData?: VacationFS) {
    // Initialize the firestore data object to default values
    this._data = new VacationFS();

    if (vData) {
      this.SetData(vData);
    }
  }

  get data(): VacationFS {
    return this._data;
  }

  private SetData(data: VacationFS) {
    // When set data from Firestore, apply business logic to not allow bad values and use defaults for missing
    // Each set function should check for null or undefined and min requirements for setting data member
    // TODO - added ! to end of assignments to tell ts values are no undefined but should check for and assign '' or undefined
    this.name = data.name;
    this.description = data.description!;
    this.notes = data.notes!;
    this.profilePhoto = data.profilePhoto!;
    this.year = data.year!;
    this.domestic = data.domestic!;
    this.foreign = data.foreign!;
    this.top = data.top!;
    this.cost = data.cost!;
    this.dateFrom = data.dateFrom!; // YYYY-MM-DD
    this.dateTo = data.dateTo!; // YYYY-MM-DD
    this.hashtags = data.hashtags!;

    // Destinations array gets loaded separately from its own Collection

    // Note: to get timestamps for created, changed, deleted, follow this sample code:
    // const timestamp = snapshot.get('created_at');
    // const date = timestamp.toDate();

  }

  get id(): string {
    return this._id;
  }
  set id(id: string) {
    this._id = id;
  }

  get name(): string {
    return this._data.name;
  }
  set name(name: string) {
    if (name) {
      this._data.name = name;
    }
  }
  get description(): string {
    return this._data.description!;
  }
  set description(description: string) {
    if (description) {
      this._data.description = description;
    }
  }
  get notes(): string {
    return this._data.notes!;
  }
  set notes(notes: string) {
    if (notes) {
      this._data.notes = notes;
    }
  }
  get profilePhoto(): string {
    return this._data.profilePhoto!;
  }
  set profilePhoto(profilePhoto: string) {
    if (profilePhoto) {
      this._data.profilePhoto = profilePhoto;
    }
  }
  get year(): string {
    return this._data.year!;
  }
  set year(year: string) {
    if (year) {
      this._data.year = year;
    }
  }
  get domestic(): boolean {
    return this._data.domestic!;
  }
  set domestic(domestic: boolean) {
    if (domestic) {
      this._data.domestic = domestic;
    }
  }
  get foreign(): boolean {
    return this._data.foreign!;
  }
  set foreign(foreign: boolean) {
    if (foreign) {
      this._data.foreign = foreign;
    }
  }
  get top(): boolean {
    return this._data.top!;
  }
  set top(top: boolean) {
    if (top) {
      this._data.top = top;
    }
  }
  get cost(): number {
    return this._data.cost!;
  }
  set cost(cost: number) {
    if (cost) {
      this._data.cost = cost;
    }
  }
  get costString(): string {
    if (this._data.cost) {
      return this._data.cost.toString();
    }
    return '';
  }

  get dateFrom(): string {
    return this._data.dateFrom!;
  }
  set dateFrom(dateFrom: string) {
    if (dateFrom) {
      this._data.dateFrom = dateFrom;
    }
  }

  get hasDateFrom(): boolean {
    return (this.dateFrom != null && this.dateFrom.length === 10);
  }
  get hasDateTo(): boolean {
    return (this.dateTo != null && this.dateTo.length === 10);
  }

  get dateFromLocaleString(): string {
    // handle empty date to leave field blank
    if (this.hasDateFrom) {
      const dateStr = new Date(this.dateFrom);
      return dateStr.toLocaleDateString(undefined, Vacation.CalDateOpt);
    }
    return Vacation.FIELD_DATEFROM_BLANK;
  }

  get dateFromShortString(): string {
    if (this.hasDateFrom) {
      const dateStr = new Date(this.dateFrom);
      return dateStr.toLocaleDateString(undefined, Vacation.ShortDateOpt);
    }
    return Vacation.FIELD_DATEFROM_BLANK;
  }

  get dateFromDate(): Date | null {
    let dateObj = null;
    if (this.dateFrom && this.dateFrom.length > 0) {
      try {
        dateObj = new Date(this.dateFrom);
      } catch (err) {
        console.log(err);
        dateObj = null;
      }
    }
    return dateObj;
  }

  get dateTo(): string {
    return this._data.dateTo!;
  }

  set dateTo(dateTo: string) {
    if (dateTo) {
      this._data.dateTo = dateTo;
    }
  }

  get dateToLocaleString(): string {
    // handle empty date to leave field blank
    if (this.hasDateTo) {
      const dateStr = new Date(this._data.dateTo!);
      return dateStr.toLocaleDateString(undefined, Vacation.CalDateOpt);
    }
    return Vacation.FIELD_DATETO_BLANK;
  }

  get dateToShortString(): string {
    if (this.hasDateTo) {
      const dateStr = new Date(this.dateTo);
      return dateStr.toLocaleDateString(undefined, Vacation.ShortDateOpt);
    }
    return Vacation.FIELD_DATETO_BLANK;
  }

  get dateToDate(): Date | null  {
    let dateObj = null;
    if (this.dateTo && this.dateTo.length > 0) {
      try {
        dateObj = new Date(this.dateTo);
      } catch (err) {
        console.log(err);
        dateObj = null;
      }
    }
    return dateObj;
  }

  get destinations(): DestinationFS[] {
    return this.destinations;
  }
  set destinations(destinations: DestinationFS[]) {
    if (destinations) {
      this.destinations = destinations;
    }
  }

  get hashtags(): { [hash: string]: boolean } {
    return this._data.hashtags!;
  }
  set hashtags(hashtags: { [hash: string]: boolean } ) {
    if (hashtags) {
      this._data.hashtags = hashtags;
      this.initHashtagsList();
    }
  }

  initHashtagsList() {
    this.hashtagsList = [];
    for (const tag in this.hashtags) {
      if (tag) {
        this.hashtagsList.push(tag);
      }
    }
  }
}
