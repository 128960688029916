import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userLoginChangeEvent: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(undefined);
  private currentUser: firebase.User = null;
  private newUserAccount = false;
  redirectUrl = '';
  loginInProgress = false;

  constructor(private firebaseAuth: AngularFireAuth,
              private router: Router) {
    // Subscribe to the authstate in AngularFire2 which wraps the authstate chang event
    // Inform subscribers when the user logs in or out to get the logged in state and current user
    this.firebaseAuth.authState.subscribe(
      user => {
        this.currentUser = user;
        if (!!user) {
          // inform the listeners about the new Firebase Auth User
          // That stats service will load the stats and UserAccount will load the user
          this.userLoginChangeEvent.next(user);
          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
          } else {
            // set flag to redirect to default after login page in settings
            this.loginInProgress = true;
          }
        } else {
          // If the user is no longer logged in then send null user event and redirect to home
          // TODO - log to the database when a user tries to login when not allowed
          // this should be if they try to use staging to login via google or via bad username/password
          // need to prevent hacking attempts so maybe add recaptcha or hide the login email form on a nonlinked page
          console.log('AuthState subscribe user = null');
          this.userLoginChangeEvent.next(null);
          this.router.navigate(['']);
        }
      }
    );
  }

  private oAuthLogin(provider: firebase.auth.GoogleAuthProvider) {
    this.firebaseAuth.signInWithPopup(provider)
      .then((credential: firebase.auth.UserCredential) => {
        // Then is called immediately upon popup with Google Account ready to go
        // Even if the user hasn't clicked to allow or login yet
        // However the new user account flag is set - TODO test login existing - is it checking Firebase???
        // listeners will automatically get notified by the auth state changed event
        // find out if this is a brand new user to configure them for the vc-app
        if (credential && credential.additionalUserInfo && credential.additionalUserInfo.isNewUser) {
          this.newUserAccount = true;
        }
      })
      .catch(error => {
        // on error this can mean auth state is user-disabled
        // this happens if disable user account from the Firebase Authentication console
        // If the user is deleted then they'll just get a new account
        console.log('AuthState Google Login catch: ' + error);
        window.location.href = environment.vc.supportUrl;
      });
  }

  get newUser(): boolean {
    return this.newUserAccount;
  }
  resetNewUser() {
    this.newUserAccount = false;
  }

  logout(): Promise<any> {
    // listeners will automatically get notified by the auth state changed event
    this.redirectUrl = '';
    return this.firebaseAuth.signOut();
  }

  getcurrentUserId(navigateOnFail: boolean = false): string {
    if (this.isAuthenticated()) {
      return this.currentUser.uid;
    }
    if (navigateOnFail) {
      this.router.navigate(['']);
    }
    return null;
  }

  getCurrentUserEmail(): string {
    if (this.isAuthenticated()) {
      return this.currentUser.email.toLowerCase();
    }
    return '';
  }

  isAuthenticated(): boolean {
    // return !!this.userLoginChangeEvent.value;
    // return this.authUser != null;

    // Note this is a ui function that return whether the user is logged in for display purposes
    // AuthGuard uses the auth-guard service which handles the checking during navigation and refresh in rxjs way
    return !!this.currentUser;
  }

  signinUserFirebaseAuthGoogle() {
    // pass in a url to redirect after successful login, otherwise goes to home page
    // default scope should get profile and email so should be no need to addScope() to provider
    const provider = new firebase.auth.GoogleAuthProvider();
    this.oAuthLogin(provider);
  }

  // Can I create a prod tag to comment out when ng build production?
  /* signupUserEmail(email: string, password: string): Promise<any> {
    return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password);
  } */

  signInUserEmail(email: string, password: string): Promise<any> {
    return this.firebaseAuth.signInWithEmailAndPassword(email, password);
  }

}
