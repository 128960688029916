import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  constructor(private http: HttpClient) { }

  getPosts(quantity: number): Observable<any[]> {
    return this.http.get<any[]>('https://www.vacationcounts.com/wp-json/wp/v2/posts?_embed', {
      params: {
        per_page: quantity.toString()
      }
    });
  }

  getPage(pageId: number): Observable<any> {
    return this.http.get<any>('https://www.vacationcounts.com/wp-json/wp/v2/pages/' + pageId.toString(), {
      params: {
      }
    })
  }

  getPages(pageIds: string) {
    return this.http.get<any>('https://www.vacationcounts.com/wp-json/wp/v2/pages?_embed', {
      params: {
        include: pageIds
      }
    })
  }

}
