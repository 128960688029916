import { NgModule } from '@angular/core';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DropZoneDirective } from './angular/drop-zone.directive';
import { VCMaterialModule } from '../design/vcmaterial.module';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    FileUploadComponent,
    DropZoneDirective,
    SanitizeHtmlPipe
  ],
  imports: [
    CommonModule,
    VCMaterialModule
  ],
  exports: [
    FileUploadComponent,
    SanitizeHtmlPipe
  ]
})

export class SharedModule {}
