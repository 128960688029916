import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth/auth.service';
import { StatsSummary, VACATION_STATS_DOC } from '../model/stats.model';


@Injectable({
  providedIn: 'root'
})
export class StatsService implements OnDestroy {

  private stats: StatsSummary = new StatsSummary();
  private statsDocument: AngularFirestoreDocument<StatsSummary> = null;
  private statsSubscription: Subscription = null;
  private userSubscription: Subscription = null;

  // Listeners can subscribe to stat change events if applicable
  public statsChangeEvent: BehaviorSubject<StatsSummary> = null;

  constructor(private readonly firestoreDB: AngularFirestore, private authService: AuthService) {

    this.statsChangeEvent = new BehaviorSubject<StatsSummary>(this.stats);

    this.userSubscription = this.authService.userLoginChangeEvent.subscribe( user => {
      if (this.statsSubscription) {
        this.statsSubscription.unsubscribe();
      }
      // Every time the user logs in then get the stats
      // This is needed because the sidebar wants the stats right away but
      // the user id is null until user is already logged in or after login
      if (user && user.uid) {
        // Load in the vacation stats for the user to pass along to dashboard and other components
        // Dashboard displays stats
        // Sidebar displays counts
        // Edit Vacation displays a list of the used hashtags
          this.statsDocument = this.firestoreDB.collection('users').doc(this.authService.getcurrentUserId(true))
            .collection('stats').doc(VACATION_STATS_DOC);
          this.statsSubscription = this.statsDocument.valueChanges().subscribe( latestStats => {
            if (latestStats) {
              this.stats = latestStats;
            } else {
              // In case the user logs out or a new user and stats doc doesn't exist yet
              this.stats = new StatsSummary();
            }
            this.statsChangeEvent.next(this.stats);
          });
        } else {
          // reset the stats to null on logout
          this.stats = new StatsSummary();
          this.statsChangeEvent.next(this.stats);
        }
    });
  }

  get LatestStats(): StatsSummary {
    return this.statsChangeEvent.value;
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.statsSubscription) {
      this.statsSubscription.unsubscribe();
    }
  }
}
