import { AccessCloud } from './accesscloud';

export const environment = {
  VERSION: '1.5.0T',

  production: false,
  firebase: {
    apiKey: AccessCloud.fbkey_test,
    authDomain: 'vacationcounts-test.firebaseapp.com',
    databaseURL: 'https://vacationcounts-test.firebaseio.com',
    projectId: 'vacationcounts-test',
    storageBucket: 'vacationcounts-test-users',
    messagingSenderId: '324234391789',
    runEmulator: false
  },
  vc: {
    appinfoUrl: 'https://www.vacationcounts.com/app?landing',
    termsUrl: 'https://www.vacationcounts.com/app/terms/',
    privacyUrl: 'https://www.VacationCounts.com/app/privacy',
    creditsUrl: 'https://www.VacationCounts.com/app/credits',
    supportUrl: 'https://www.VacationCounts.com/app/support',
    helpUrl: 'https://www.VacationCounts.com/app/help',
    blogUrl: 'https://www.VacationCounts.com/blog',
    upgradeUrl: 'https://www.vacationcounts.com/app?upgrade',
    meliovationUrl: 'https://www.meliovation.com?app',
    shareUrl: 'https://www.VacationCounts.com/share',
    allowEmail: true
  },
  google: {
    googlecharts: AccessCloud.googleapi_test
  }
};
