import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, finalize, catchError } from 'rxjs/operators';

declare var loadImage: any;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @ViewChild("fileUploadInput") fileUploadInput: ElementRef;

  @Input() storageBucketPath: string;
  @Input() photoSize: number = 360;

  // Emit the file path to the uploaded photos
  @Output() uploadPhotoDone = new EventEmitter<string>();

  // Main task
  photoUploadTask: AngularFireUploadTask;

  // Progress monitoring
  uploadState$: Observable<string>;
  uploadProgress$: Observable<number>;

  // Download URL to display thumb after uploading
  downloadUrlSubscripton: Subscription;
  public downloadUrl: string = null;
  uploadFailed: boolean = false;
  uploadFailedMsg: string = "";

  // State for dropzone CSS toggling
  isHovering = false;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  selectUpload(event: Event) {
    const target = event.target as HTMLInputElement;
    this.startUpload(target.files);
  }

  startUpload(files: FileList) {
    const file = files.item(0);

    this.clearUploadError();

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      this.displayUploadError("The photo you selected is not a valid image file. Try again with a .png or .jpg/.jpeg photo file stored on your computer, drive, or phone.");
      return;
    }

    // new test code for BlueImp Load Image javascript library which should handle scale, crop, canvas
    loadImage(file, (img: HTMLImageElement | Event) => {
      if(img instanceof Event && img.type === "error") {
        this.displayUploadError("Your selected image file could not be loaded. Make sure you selected a valid photo and try again.");
        return;
      }
      img = img as HTMLImageElement;

      if (img.width < this.photoSize || img.height < this.photoSize) {
        // Tell the user if the resize failed due to file not being large enough
        this.displayUploadError("File is not large enough. Try again with a photo (jpg or png file) of at least 360 pixels in height and width. Doesn't need to be square.");
        return;
      }

      // create an equal sized width and height box
      // Take the smaller size and scale down the other size to the set width or height for cropping after
      let newWidth: number = img.width;
      let newHeight: number = img.height;
      if (img.width >= img.height) {
        // const aspectRatio: number = image.width / image.height;
        newWidth = img.height;
        newHeight = img.height;
      } else {
        // const aspectRatio: number = image.height / image.width;
        newWidth = img.width;
        newHeight = img.width;
      }

      // scale and draw the image centered which crops
      let drawStartX = 0;
      let drawStartY = 0;
      if (img.width >= img.height) {
        drawStartX = Math.round((img.width - newWidth) / 2);
      } else {
        drawStartY = Math.round((img.height - newHeight) / 2);
      }

      const canvas: HTMLCanvasElement = loadImage.scale(img,
      {
        canvas: true,
        crop: true,
        maxWidth: this.photoSize,
        maxHeight: this.photoSize,
        sourceWidth: newWidth,
        sourceHeight: newHeight,
        top: drawStartY,
        left: drawStartX
      });

      // turn into blob using blueimg toblob library as many browsers don't support the
      // built in mozilla html canvas element toBlog() function - adds polyfill
      if (canvas.toBlob) {
        canvas.toBlob((blob) => {
          // Upload to firestore storage path
          // NOTE: If the user fails to save a new vacation there will be an orphan file
          // and if in edit mode the image will replace the old regardless of save
          // this.uploadToFirebaseStorage(this.storageBucketThumb, fileData, false);
          this.uploadPhotoToFirebaseStorage(this.storageBucketPath, blob, this.photoSize, this.photoSize);
        }, 'image/jpeg');
      } else {
        // blob support not working? not sure why
        this.displayUploadError("Failed to process your photo for uploading to the cloud. It could be a browser compatibility issue.");
        return;
      }
    },
    {
      orientation: true,
    });
  }

  uploadPhotoToFirebaseStorage(bucketPath: string, fileData: any, width: number, height: number) {
    const customMetadata = {
      app: 'vc',
      width: width.toString(),
      height: height.toString()
    };

    // The main task
    const photoStorageRef: AngularFireStorageReference = this.storage.ref(bucketPath);
    this.photoUploadTask = photoStorageRef.put(fileData, { customMetadata });

    // Progress monitoring
    this.uploadState$ = this.photoUploadTask.snapshotChanges().pipe(map(s => s.state));
    this.uploadProgress$ = this.photoUploadTask.percentageChanges();

    this.photoUploadTask.snapshotChanges().pipe(
      catchError( (err: Error) => {
        // Display error message - dont bother retrying
        this.displayUploadError("Photo upload failed. Check your internet or mobile connection and try again. Make sure the photo isn't too large to upload.");
        console.log("file upload error: " + err);
        return of([]);
      }),
      finalize( () => {
        photoStorageRef.getDownloadURL().subscribe( url => {
          console.log(url);
          this.downloadUrl = url;
          this.uploadPhotoDone.emit(url);
        });
      }),
    ).subscribe();

  }

  private clearUploadError() {
    this.uploadFailed = false;
    this.uploadFailedMsg = "";
  }

  private displayUploadError(msg: string) {
    this.uploadFailed = true;
    this.uploadFailedMsg = msg;
    this.fileUploadInput.nativeElement.value = "";
  }
}

