import * as moment from 'moment';

export enum Membership {
  Free = 'FREE',  // V1.0
  Admin = 'ADMIN',  // V1.0
  Test = 'TEST',  // V1.0
  Premium = "PREM", // V1.5
  // Unlimited = 'UNLIMITED'  // V2.0
}

export enum UserSignupType {
  WEB = 'WEB' // V1.0
}

export enum ViewMode {
  LIST = 'LIST',
  GRID = 'GRID'
}

export enum PageMode {
  DASHBOARD = 'DASHBOARD',
  VACATIONS = 'VACATIONS',
  UPCOMING = 'UPCOMING',
  TIMEOFF = 'TIMEOFF'
}

export enum SortMode {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  DATE_ASC = 'DATE_ASC',
  DATE_DESC = 'DATE_DESC'
}
const SortModeSetting = 'SortMode';

export class UserAccountFS {
  displayName: string;
  contactEmail: string;
  signup: any; // stores firestore server timestamp
  source: string;
  countryCode?: string;
  membership: Membership;
  newsletter: boolean;
  prefs: { [prefKey: string]: string } = {};
  // prefs: Map<string, string>;
  currentSortMode: SortMode = null;
  lastSignin: any;
  closed: any; // stores firestore server timestamp

   // meta-_data
   created?: any; // stores firestore server timestamp
   changed?: any; // stores firestore server timestamp
   deleted?: any; // stores firestore server timestamp

   constructor() {
     this.displayName = '';
     this.contactEmail = '';
     this.signup = null;
     this.source = '';
     this.countryCode = '';
     this.membership = Membership.Free;
     this.newsletter = false;
     this.prefs = {};
     this.closed = null;
   }
}

export class UserAccount {
  // class members
  private _id: string; // Firebase key
  private _data: UserAccountFS; // interface model _data for Firestore

  private _isNewUser = false;

  private _signupDate: Date;

  constructor(data?: UserAccountFS) {
    // Initialize the firestore data object to default values
    this._data = new UserAccountFS();

    if (data) {
      this.SetData(data);
    }
  }

  get data(): UserAccountFS {
    return this._data;
  }

  private SetData(data: UserAccountFS) {
    // When set data from Firestore, apply business logic to not allow bad values and use defaults for missing
    // Each set function should check for null or undefined and min requirements for setting data member
    this.displayName = data.displayName;
    this.contactEmail = data.contactEmail;
    this.signup = data.signup;
    this.source = data.source;
    this.membership = data.membership;
    this.newsletter = data.newsletter;
    this.prefs = data.prefs;
    this.closed = data.closed;

    this.setIsNewUser();
  }

  get id(): string {
    return this._id;
  }
  set id(id: string) {
    this._id = id;
  }

  get displayName(): string {
    return this._data.displayName;
  }
  set displayName(displayName: string) {
    this._data.displayName = displayName;
  }

  get contactEmail(): string {
    return this._data.contactEmail;
  }
  set contactEmail(contactEmail: string) {
    this._data.contactEmail = contactEmail;
  }

  get signup(): Date {
    return this._data.signup;
  }
  set signup(signup: Date) {
    if (signup) {
      this._data.signup = signup;
    }
  }
  get source(): string {
    return this._data.source;
  }
  set source(source: string) {
    if (source) {
      this._data.source = source;
    }
  }
  get countryCode(): string {
    return this._data.source;
  }
  set countryCode(countryCode: string) {
    if (countryCode) {
      this._data.countryCode = countryCode;
    }
  }
  get membership(): Membership {
    return this._data.membership;
  }
  set membership(membership: Membership) {
    if (membership) {
      this._data.membership = membership;
    }
  }
  get newsletter(): boolean {
    return this._data.newsletter;
  }
  set newsletter(newsletter: boolean) {
    if (newsletter) {
      this._data.newsletter = newsletter;
    }
  }
  get prefs(): { [prefKey: string]: string } {
    return this._data.prefs;
  }
  set prefs(prefs: { [prefKey: string]: string }) {
    if (prefs) {
      this._data.prefs = prefs;
    }
  }

  // Individual Prefs
  get prefVacationView(): ViewMode {
    if (this.prefs['VacationView']) {
      return this.getEnumKeyByEnumValue(ViewMode, this.prefs['VacationView']) as ViewMode;
    }

    // Default to photo view
    return ViewMode.GRID;
  }

  get prefVacationSort(): SortMode {
    if (this.prefs['VacationSort']) {
      return this.getEnumKeyByEnumValue(SortMode, this.prefs['VacationSort']) as SortMode;
    }
    // Default to photo view
    return SortMode.DATE_DESC;
  }

  get prefAfterLoginPage(): PageMode {
    if (this.prefs['AfterLoginPage']) {
      return this.getEnumKeyByEnumValue(PageMode, this.prefs['AfterLoginPage']) as PageMode;
    }
    // Default to photo view
    return PageMode.DASHBOARD;
  }

  get closed(): Date {
    return this._data.closed;
  }
  set closed(closed: Date) {
    if (closed) {
      this._data.closed = closed;
    }
  }

  get signupDate(): Date {
    return this._signupDate;
  }
  set signupDate(signupDate: Date) {
    this._signupDate = signupDate;
  }

  setIsNewUser() {
    // Set the new user flagif the user account was created in the last 30 days
    // this displays the new user welcome message and how-to instructions on the home page
    // only need to set once at startup as it doesn't need to be monitored for change of value
    const joinDate: moment.Moment = moment(this.signup);
    if (joinDate.add(30, 'days') > moment()) {
      this._isNewUser = true;
    }
    this._isNewUser = false;
  }

  getEnumKeyByEnumValue<T extends {[index:string]:string}>(myEnum:T, enumValue:string):keyof T|null {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
}
