import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private firebaseAuth: AngularFireAuth,
              private authService: AuthService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // This is called during navigation to pages that require firebase authenticated users
    // Take is used to get the current authstate right away from the observable and
    // handle the situtation where the user refreshes the page or loads a subpage to avoid
    // canActivate returning false and redirecting to the login page before the auth is ready
    return this.firebaseAuth.authState.pipe(
      take(1),
      map(user => !!user ),
      tap( loggedIn => {
        if (!loggedIn) {
          // Store the requested url to redirect after successful login
          this.authService.redirectUrl = state.url;
          this.router.navigate(['signin']);
        }
       } )
    );
  }
}
