import { Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';

import { VacationFS, Vacation } from '../../model/vacation.model';
import { VacationService } from '../../services/vacation.service';
import { DestinationService } from '../../services/destinations.service';
import { Destination, DestinationFS, DestinationType } from '../../model/destination.model';
import { StorageService } from '../../services/storage.service';
import { VacationConstants } from '../vacation-constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-vc-vacation-view',
  templateUrl: './vc-vacation-view.component.html',
  styleUrls: ['./vc-vacation-view.component.scss']
})
export class VcVacationViewComponent implements OnInit, OnDestroy {

  vacationId: string;
  vacation: Vacation = new Vacation();
  destinations: Destination[] = new Array<Destination>();

  subParams: Subscription;
  subVacation: Subscription;
  subDestinations: Subscription;
  readonly photoDefault = VacationConstants.profilePhotoDefault;

  constructor(private route: ActivatedRoute,
    private vacationService: VacationService,
    private destinationService: DestinationService,
    private storageService: StorageService,
    private router: Router,
    private titleService: Title) {
    }

  ngOnInit() {
    this.titleService.setTitle("View Vacation - VacationCounts");

    this.subParams = this.route.params.subscribe(
      (params: Params) => {
        this.vacationId = params['id'];
        this.initVacationData();
        this.initDestinationData();
      }
    );
  }

  ngOnDestroy() {
    if (this.subParams) {
      this.subParams.unsubscribe();
    }
    if (this.subVacation) {
      this.subVacation.unsubscribe();
    }
    if (this.subDestinations) {
      this.subDestinations.unsubscribe();
    }
  }

  initVacationData() {
    // Load the vacation object from the database
    if (this.subVacation) {
      this.subVacation.unsubscribe();
    }
    this.subVacation = this.vacationService.getVacationById(this.vacationId).subscribe(
      (vacationData) => {
        console.log("get vacation to view from service");
        this.vacation = vacationData;
      }
    );
  }

  initDestinationData() {
    // Load the vacation object from the database
    if (this.subDestinations) {
      this.subDestinations.unsubscribe();
    }
    this.subDestinations = this.destinationService.getDestinationsForVacation(this.vacationId).subscribe(
      (destData) => {
        console.log("get destinations collection to view from service");
        this.destinations = destData;
      }
    );
  }

  onEdit() {
    // navigate to the edit view for this vacation id
    this.router.navigate(['/vacations', this.vacationId, 'edit']);
  }

  hasPhoto(): boolean {
    return (this.vacation && this.vacation.profilePhoto && this.vacation.profilePhoto.length > 0);
  }

}
